import { closeModal, openModal, unitsCreate, unitsListing, unitsListingSimple, uqcCreate, uqcListing } from '../../../../../redux/actions/action-creator';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import FormData from 'form-data';
import Form from '../../../../../components/Form/Form';
import { prepareFormData, useFormHandler } from '../../../../../components/Custom_Hooks/useFormHandler';
import useClickOutside from '../../../../../components/Custom_Hooks/useClickOutsSide';
import InputField from '../../../../../components/Input/InputField';
import DropdownLayout_1 from '../../../../../components/Dropdown/DropdownLayout_1';
import MasterCreationFooter from '../../../../../components/Footer/Master_Footer/MasterCreationFooter';
import { useInputActionUnit } from '../../../../../components/Custom_Hooks/useInputActionUnit';
const type_of_unit_array = [{ id: 1, name: 'Simple' }, { id: 2, name: 'Compound' }]

const Unit = ({ title, closeModal_sec }) => {
  const dispatch = useDispatch();
  const [nameInput, setNameInput] = useState(null)

  const modal_state = useSelector(state => state.common.modal_state);
  const { unit_data = null } = useSelector(state => state.common);
  const { unit_data_simple = null } = useSelector(state => state.common);
  const { uqc_code_response = null } = useSelector(state => state.common);
  const { userData = null } = useSelector(state => state.auth);

  const initialState = {
    type: 'Simple',
    symbol: '',
    formal_name: '',
    unit_quantity_code: 'Not Applicable',
    uqc_id: 'Not Applicable',
    no_decimal_places: '0',
    first_unit: '',
    first_unit_id: '',
    conversion: '',
    second_unit: '',
    secound_unit_id: '',
    uqc_code_create_name: '',
    company_id: userData?.id
  }
  const [data, setData] = useState(initialState);
  // Filter Second Unit Dropdown
  const filter_secound_unit = unit_data.filter(unit => {
    if (unit.type === "Simple" && unit.symbol === data?.first_unit_id) return false;
    if (unit.type === "Compound" && unit.symbol.includes(data?.first_unit_id)) return false;
    return true;
  });
  const inputRefs = {
    type: useRef(null),
    symbol: useRef(null),
    formal_name: useRef(null),
    unit_quantity_code: useRef(null),
    no_decimal_places: useRef(null),
    first_unit_id: useRef(null),
    conversion: useRef(null),
    secound_unit_id: useRef(null),
  };


  function getCurrentInputRef(name) {
    return inputRefs[name] || null;
  }
  const inputFields = [
    { label: 'Type', name: 'type', type: 'text', isRequired: true, },
    { label: 'Symbol', name: 'symbol', type: 'text', isRequired: true, },
    { label: 'Formal Name', name: 'formal_name', type: 'text', isRequired: false, },
    { label: 'Unit Quantity Code (UQC)', name: 'unit_quantity_code', type: 'text', isRequired: false },
    { label: 'Number of Decimal Places', name: 'no_decimal_places', type: 'text', isRequired: false, },
    { label: 'First Unit', name: 'first_unit_id', type: 'text', isRequired: true },
    { label: 'Conversion of', name: 'conversion', type: 'text', isRequired: true },
    { label: 'Secound Unit', name: 'secound_unit_id', type: 'text', isRequired: true },
  ];


  const cleanString = (str) => str?.replace(/[^a-zA-Z0-9]/g, "")?.toLowerCase();
  const validations = {
    symbol: (value) => value.trim() !== "",
    conversion: (value) => value.trim() !== "",
    no_decimal_places: (value) => value.trim() !== "",
    formal_name: (value) => value.trim() !== "",
    unit_quantity_code: (value) => uqc_code_response?.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    type: (value) => type_of_unit_array?.some(item => cleanString(item?.name)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
    first_unit_id: (value) => unit_data?.some(item => cleanString(item?.symbol)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
    secound_unit_id: (value) => filter_secound_unit?.some(item => cleanString(item?.symbol)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
  };

  const refs = Object.keys(inputRefs).reduce((acc, name) => {
    acc[name] = {
      onFocus: () => {
        const ref = getCurrentInputRef(name);
        if (ref) {
          setNameInput(ref)
          ref?.current?.setSelectionRange(0, ref?.current?.value?.length)
        };
        if (['type', 'unit_quantity_code', 'first_unit_id', 'secound_unit_id'].includes(name)) {
          if (name) {
            dispatch(openModal(name));
          }
        }

      },
      onBlur: (value) => {
        if (['type', 'unit_quantity_code', 'first_unit_id', 'secound_unit_id'].includes(name)) {
          if (name) {
            dispatch(closeModal(name));
          }
        }
      },
    };
    return acc;
  }, {});


  const validateForm = () => {
    let requiredFields;
    if (data?.type === 'Simple') {
      requiredFields = [
        { field: 'type', message: 'Required Type Field' },
        { field: 'symbol', message: 'Required Symbol Field' },
        { field: "unit_quantity_code", message: 'Required Uqc Field' },
        { field: "no_decimal_places", message: 'Required No Decimal Place Field' },
      ];
    } else {
      requiredFields = [
        { field: 'type', message: 'Required Type Field' },
        { field: 'first_unit_id', message: 'Required First unit id' },
        { field: 'conversion', message: 'Required Conversion Field' },
        { field: 'secound_unit_id', message: 'Required secound unit id' },
      ];
    }
    for (let { field, message } of requiredFields) {
      if (!data?.[field]) {
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setSubmitConfirm('');
        getCurrentInputRef(field)?.current?.focus();
        return false;
      }
    }
    return true;
  };


  const resetForm = () => {
    setData(initialState)
  };
  const { handleChange, handleFocus, handleBlur, handleKeyDown, setSubmitConfirm, submitConfirm, handleMouseFocus } = useInputActionUnit({
    data,
    setData,
    validations,
    refs,
    check_dropdown: false,
    inputRefs
  });

  const { handleSubmit, loading, handleFocusReset } = useFormHandler({
    validateForm,
    prepareFormData: () => prepareFormData(data),
    submitForm: (data) => dispatch(unitsCreate(data)),
    shortcuts: [
      {
        key: 'a',
        ctrlKey: true,
        action: () => handleSubmit(new Event('submit')),
      },
    ],
    resetForm,
    first_ref: data?.type === 'Simple' ? inputRefs?.symbol : inputRefs?.first_unit_id,
    closeModal: closeModal_sec,
    submitConfirm,
    setSubmitConfirm
  });


  useEffect(() => {
    const formData = new FormData();
    formData.append('company_id', userData?.id)
    dispatch(uqcListing(formData))
    dispatch(unitsListing(formData))
    dispatch(unitsListingSimple(formData))
  }, [loading])

  const handleClickOutside = (event) => {
    if (nameInput.current) {
      event.preventDefault();
      nameInput.current.focus();
    }
  };

  const handleClickInside = (event) => {
    let clickedElement = event.target;
    if (clickedElement?.tagName &&
      (clickedElement?.tagName.toLowerCase() === 'input' ||
        clickedElement?.tagName.toLowerCase() === 'textarea')) {
      clickedElement.focus();
    }
  };
  useClickOutside(nameInput, handleClickOutside, handleClickInside);
  const dropdownConfig = {
    type: { title: 'List of Unit Type', data: type_of_unit_array },
    unit_quantity_code: { title: 'List of Unit Quantity Code', input_id: 'uqc_id', data: [{ id: 'Not Applicable', name: 'Not Applicable' }, ...uqc_code_response] },
    first_unit_id: { title: 'List of Unit', input_id: 'first_unit', data: unit_data_simple },
    secound_unit_id: { title: 'List of Unit', input_id: 'second_unit', data: filter_secound_unit },
  };
  return (
    <Form
      handleSubmit={handleSubmit}
      handleKeyDown={handleKeyDown}
      submitConfirm={submitConfirm}
      handleFocusReset={handleFocusReset}
      title={title || 'Unit Creation'}
      loading={loading}
      col='col-6'
      Footer={MasterCreationFooter}
    >
      <div className='row p-0 ps-2 m-0 mb-1'>
        {inputFields.slice(0, 1).map(({ label, name, type, isRequired }, index) => (
          <InputField
            key={index}
            lable={label}
            type={type}
            name={name}
            value={data[name]}
            handleMouseFocus={handleMouseFocus}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleFocus={handleFocus}
            isRequired={isRequired}
            ref={inputRefs[name]}
          />
        ))}
        {Object.entries(dropdownConfig).map(([key, config]) => (
          modal_state?.[key] && (
            <DropdownLayout_1
              key={key}
              title={config.title}
              state={modal_state[key]}
              data={data}
              setData={setData}
              input_value={data[key]}
              input_key={key}
              input_id={config?.input_id}
              rawData={config.data}
              ref={inputRefs[key]}
              inputRefs={inputRefs}
              setSubmitConfirm={setSubmitConfirm}
            />
          )
        ))}
        {
          data?.type === 'Simple' ?
            <>
              {inputFields.slice(1, 5).map(({ label, name, type, isRequired }, index) => (
                <InputField
                  key={index}
                  lable={label}
                  type={type}
                  name={name}
                  value={data[name]}
                  handleMouseFocus={handleMouseFocus}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleFocus={handleFocus}
                  isRequired={isRequired}
                  ref={inputRefs[name]}
                />
              ))}
            </> :
            <>
              <div className='col-12 d-flex align-items-center justify-content-center flex-column'>
                <p className='m-0 mt-1 p-0'><strong><u>Units with multiplier Factors</u></strong></p>
                <p className='m-0 p-0'>(example: kgs of 1000 gms)</p>
              </div>
              {inputFields.slice(5).map(({ label, name, type, isRequired }, index) => (
                <InputField
                  key={index}
                  lable={label}
                  type={type}
                  name={name}
                  value={data[name]}
                  handleMouseFocus={handleMouseFocus}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleFocus={handleFocus}
                  isRequired={isRequired}
                  ref={inputRefs[name]}
                />
              ))}
            </>
        }
      </div>
    </Form>
  )
}

export default Unit
