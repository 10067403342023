import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ledgerDelete, groupDelete, ledgerList, closeModal, openModal } from '../../../../../redux/actions/action-creator';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import MasterListFooter from '../../../../../components/Footer/Master_Footer/MasterListFooter';
import { useDropdownKeyboardNavigation } from '../../../../../components/Custom_Hooks/useDropdownKeyboardNavigation';
import { useDropdownScroll } from '../../../../../components/Custom_Hooks/useDropdownScroll';
import HomeLayout from '../../../../../components/home_page/HomeLayout';
import Form from '../../../../../components/Form/Form';
import NavFooterLayout from '../../../../../components/NavNameOfComponent/NavFooterLayout';
import DeletConfirm from '../../../../../components/Modal/DeletConfirm';
import { useDelet } from '../../../../../components/Custom_Hooks/useDelete';
import { prepareFormData } from '../../../../../components/Custom_Hooks/useFormHandler';
import GroupAlter from '../../alter/accounting_masters/GroupAlter';
import LedgerAlter from '../../alter/accounting_masters/Ledger';
import GroupCreate from '../../create/accounting_masters/Group';
import LedgerCreate from '../../create/accounting_masters/Ledger';

const Ledger = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [all_data, setall_data] = useState([])
    const [ledger_length, setledger_length] = useState(0)
    const [group_length, setgroup_length] = useState(0)

    const { userData } = useSelector(state => state.auth);
    const modal_state = useSelector(state => state.common.modal_state);
    const dropdownRef = useRef(null);



    // fetch data ----------------------------------
    useEffect(() => {
        const fetchData = async () => {
            try {
                const formdata = new FormData();
                formdata.append('company_id', userData?.id);
                const response = await dispatch(ledgerList(formdata));

                const processGroups = (groups, type) => {
                    let groupCount = 0;
                    let ledgerCount = 0;
                    const array_list = [];

                    groups.forEach(group => {
                        array_list.push({ type, data: group });

                        if (group.groupName) {
                            const { groupCount: subGroupCount, ledgerCount: subLedgerCount, array_list: subArray } = processGroups(group.groupName, type + 1);
                            groupCount += subGroupCount;
                            ledgerCount += subLedgerCount;
                            array_list.push(...subArray);
                        }

                        if (group.ledger) {
                            ledgerCount += group.ledger.length;
                            array_list.push(...group.ledger.map(ledger => ({ type: type + 1, data: ledger })));
                        }
                    });

                    groupCount += groups.length;
                    return { groupCount, ledgerCount, array_list };
                };

                if (response.data && response.data.length > 0) {
                    const { groupCount, ledgerCount, array_list } = processGroups(response.data, 'primary');
                    setall_data(array_list);
                    setledger_length(ledgerCount);
                    setgroup_length(groupCount - 4);
                    // Now you have groupCount and ledgerCount containing the lengths of groups and ledgers respectively
                } else {
                    setall_data([]);
                }
            } catch (error) {
                console.error('Error fetching ledger data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    },
        [
            modal_state?.group_update,
            modal_state?.ledger_update,
            modal_state?.ledger_create,
            modal_state?.modal_1,
            modal_state?.modal_2
        ]);


    const { selectedItemIndex, setSelectedItemIndex } = useDropdownKeyboardNavigation({
        items: all_data,
        state: modal_state?.group_update || modal_state?.modal_1 || modal_state?.modal_2 ? false : true,
        onItemSelect: (item) => {
            // navigate(`../alter_accounting_groupupdate/${item?.data?.id}`)
        },
        onItemControlEnterSelect: (item) => {
            if (item.data.name === 'Assets' || item.data.name === 'Expenses' || item.data.name === 'Income' || item.data.name === 'Liabilities') { return }
            if (!(modal_state?.modal_1 || modal_state?.modal_2)) {
                if (item?.data?.name) {
                    // navigate(`../alter_accounting_groupupdate/${item?.data?.id}`)
                    dispatch(openModal('group_update'))
                } else if (item?.data?.ledger_name) {
                    // navigate(`../alter_accounting_ledgerupdate/${item?.data?.id}`)
                    dispatch(openModal('ledger_update'))
                }
            }
        },
        onItemAltCSelect: (item) => {
            if (item.data.name === 'Assets' || item.data.name === 'Expenses' || item.data.name === 'Income' || item.data.name === 'Liabilities') { return }
            if (!(modal_state?.group_update || modal_state?.modal_2)) {
                if (item?.data?.name) {
                    dispatch(openModal('modal_1'))
                } else if (item?.data?.ledger_name) {
                    dispatch(openModal('ledger_create'))
                }
            }
        },
        onItemAltDSelect: (item) => {
            if (item.data.name === 'Assets' || item.data.name === 'Expenses' || item.data.name === 'Income' || item.data.name === 'Liabilities') { return }
            if (!(modal_state?.group_update || modal_state?.modal_1)) {
                dispatch(openModal('modal_2'))
            }
        },
    });
    // Scroll Functionality -------------------------------------
    useDropdownScroll({ dropdownRef, selectedItemIndex });

    const [data, setData] = useState({
        id: all_data[selectedItemIndex]?.data?.id,
        company_id: userData?.id
    })

    useEffect(() => {
        if (all_data[selectedItemIndex]) {
            setData({
                id: all_data[selectedItemIndex]?.data?.id,
                company_id: userData?.id,
            });
        }
    }, [selectedItemIndex, all_data]);

    // Delete Functionality ------------------------------
    const { delteItem, loadingDelete } = useDelet({
        prepareFormData: () => prepareFormData(data),
        submitForm: (data) => dispatch(all_data[selectedItemIndex]?.data?.name ? groupDelete(data) : ledgerDelete(data)),
        shortcuts: [
            {
                key: 'a',
                ctrlKey: true,
                action: () => delteItem(new Event('submit')),
            },
        ],
        closeModal: () => dispatch(closeModal('modal_2')),
        state: modal_state?.modal_2
    });
    return (
        <HomeLayout>
            {modal_state?.modal_1 && (
                <div style={{ zIndex: 10000 }}>
                    <GroupCreate
                        title='Group Creation (Secondary)'
                        closeModal_sec={() => dispatch(closeModal('modal_1'))}
                    />
                </div>)}
            {modal_state?.ledger_create && (
                <div style={{ zIndex: 10000 }}>
                    <LedgerCreate
                        title='Ledger Creation (Secondary)'
                        closeModal_sec={() => dispatch(closeModal('ledger_create'))}
                    />
                </div>)}
            {
                // location.pathname === `/alter_accounting_groupupdate/${id}`
                modal_state?.group_update
                && (
                    <div style={{ zIndex: 10000 }}>
                        <GroupAlter
                            data_to_update={data}
                            title='Group Alteration'
                            closeModal_sec={() => dispatch(closeModal('group_update'))}
                        />
                    </div>
                )}
            {
                // location.pathname === `/alter_accounting_groupupdate/${id}`
                modal_state?.ledger_update
                && (
                    <div style={{ zIndex: 10000 }}>
                        <LedgerAlter
                            data_to_update={data}
                            title='Ledger Alteration'
                            closeModal_sec={() => dispatch(closeModal('ledger_update'))}
                        />
                    </div>
                )}
            <Form
                title='List of Ledger'
                col='col-12'
                loading={loading}
                Footer={MasterListFooter}
            >
                {
                    modal_state?.modal_2 ?
                        <div style={{ zIndex: 10000 }}>
                            <DeletConfirm
                                handleSubmit={delteItem}
                                loading={loadingDelete}
                                closeModal={() => dispatch(closeModal('modal_2'))}
                            />
                        </div>
                        : null
                }
                <div className='row p-0 mb-2' style={{ overflowY: 'hidden' }}>
                    <div className='col m-0 p-0 ps-2' ref={dropdownRef} style={{ overflowY: 'auto', height: '81.3vh' }}>
                        {
                            all_data.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={index === selectedItemIndex ? 'selected' : ''}
                                        style={{
                                            display: 'flex',
                                            textAlign: 'left',
                                            paddingLeft: 5,
                                            marginLeft: item.type === 'primary' ? 0 : item.type === 'primary1' ? 0 : item.type.startsWith('primary1') ? (item.type.length - 6) * 5 : 0
                                        }}
                                    >
                                        <Link
                                            onClick={() => setSelectedItemIndex(index)}
                                            className={'group_list_chart_of_account'}
                                            style={{
                                                width: '100%',
                                                fontSize: item.data.name === 'Assets' || item.data.name === 'Expenses' || item.data.name === 'Income' || item.data.name === 'Liabilities' ? 15 : '',
                                                color: item.data.name === 'Assets' || item.data.name === 'Expenses' || item.data.name === 'Income' || item.data.name === 'Liabilities' ? '#000000' : item.data.name ? '#000000' : '#4B0082',
                                                //  color: item.data.name ? '#000000' : '#212529',
                                                //  fontWeight: item.data.name ? 'bold' : '#212529',

                                            }}
                                        >
                                            {item.data.name || item.data.ledger_name}
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <NavFooterLayout>
                    <b className='ps-2'> {group_length} Group(s) And {ledger_length} Ledger(s)</b>
                </NavFooterLayout>

            </Form>
        </HomeLayout>
    )
}
export default Ledger



