import React, { forwardRef, useRef } from 'react'
import { Link } from 'react-router-dom';
import { useDropdownFilter } from '../Custom_Hooks/useDropdownFilter';
import { useDropdownKeyboardNavigation } from '../Custom_Hooks/useDropdownKeyboardNavigation';
import { useDropdownScroll } from '../Custom_Hooks/useDropdownScroll';
import { flushSync } from 'react-dom';
import { useDispatch } from 'react-redux';
import { closeModal, openModal } from '../../redux/actions/action-creator';


const DropdownLayout_1 = forwardRef(({ title, state, input_value, input_key, input_id, rawData, data, setData, setSubmitConfirm, inputRefs }, ref) => {
    const dropdownRef = useRef(null)
    const dispatch = useDispatch();
    const { filteredData } = useDropdownFilter({
        dataList: rawData,
        searchInput: input_value,
        inputRef: ref,
    });
    const { selectedItemIndex, setSelectedItemIndex } = useDropdownKeyboardNavigation({
        items: filteredData,
        state,
        onItemSelect: (item) => {

            flushSync(() => {
                const updatedData = {
                    ...data,
                    [input_key]: item?.name || item?.ledger_name || item?.symbol, // Update with item name
                };

                if (input_id) {
                    updatedData[input_id] = item?.id; // Update with item id if input_id exists
                }

                if (input_id === 'group_id') {
                    updatedData['group_slug'] = item?.slug; // Add slug if input_id is 'group_id'
                    updatedData['nature_of_group'] = item?.nature_of_group; // Add nature_of_group if input_id is 'group_id'
                }
                if (input_key === 'unit_name') {
                    updatedData['unit_name_first_unit'] = item?.first_unit ? item?.first_unit : '';
                    updatedData['unit_name_second_unit'] = item?.second_unit ? item?.second_unit : '';
                    updatedData['unit_name_third_unit'] = item?.third_unit ? item?.third_unit : '';
                    updatedData['unit_name_fourth_unit'] = item?.fourth_unit ? item?.fourth_unit : '';
                    updatedData['unit_name_fifth_unit'] = item?.fifth_unit ? item?.fifth_unit : '';
                }
                if (input_key === 'secound_unit_id' || input_key === 'alt_unit_name') {
                    if (input_key === 'alt_unit_name') {
                        const unitKeys = ["first_unit", "second_unit", "third_unit", "fourth_unit", "fifth_unit"];
                        unitKeys.forEach((key, index) => {
                            updatedData[`alt_unit_name_${key}`] = item?.[key] ? item?.[key] : '' || '';
                        });
                        dispatch(closeModal(input_key));
                    }

                } else {
                    dispatch(closeModal(input_key))
                }

                setData(updatedData);

            });

        },
        input_value,
    });

    useDropdownScroll({ dropdownRef, selectedItemIndex });
    return (
        <div className={`dropdown-content-list ${state ? 'show-list' : ''}`}>
            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                {title}
            </div>

            <div id='data' ref={dropdownRef} style={{ overflowY: 'auto', maxHeight: '80.5vh' }}>
                {
                    Array.isArray(filteredData) && filteredData?.map((key, number) => {
                        const name = key?.ledger_name || key?.name || key?.symbol;
                        const formal_name = key?.formal_name
                        const type = key?.type
                        const balance = key?.opening_balance;
                        const id = key?.id;
                        const index = name?.toLowerCase()?.indexOf(input_value?.toLowerCase());
                        return (
                            <Link
                                className={`list  ${number === selectedItemIndex ? 'selected' : ''}`}
                                id='list'
                                key={number}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedItemIndex(number)
                                    if (number === selectedItemIndex) {
                                        const updatedData = {
                                            ...data,
                                            [input_key]: name,
                                        };

                                        if (input_id) {
                                            updatedData[input_id] = id;
                                        }
                                        if (input_id === 'group_id') {
                                            updatedData['slug'] = filteredData[selectedItemIndex]?.slug;
                                            updatedData['nature_of_groups'] = filteredData[selectedItemIndex]?.nature_of_group;

                                        }
                                        // if (item?.opening_balance) {
                                        //     updatedData[`${input_key}_bal`] = item?.opening_balance;  // Set the balance if available
                                        // }
                                        setData(updatedData);
                                        let nextField = null;
                                        if (input_key === "weight") {
                                            nextField = updatedData?.weight === "Available" ? "per_unit" : "set_standard_rate";
                                        } else if (input_key === "provide_behaviour_opt") {
                                            nextField = data?.provide_behaviour_opt === "Yes" ? "ignore_diff_physical" : "quantity";
                                        } else {
                                            // Default sequential navigation
                                            const fields = Object.keys(inputRefs);
                                            const currentIndex = fields.indexOf(input_key);
                                            nextField = fields[currentIndex + 1] || null;
                                        }

                                        if (nextField && inputRefs[nextField]?.current) {
                                            inputRefs[nextField].current.focus();
                                            inputRefs[nextField].current.setSelectionRange(0, inputRefs[nextField].current.value.length);
                                        } else {
                                            setSubmitConfirm('Accept')
                                            inputRefs[input_key]?.current?.blur();
                                        }
                                        dispatch(closeModal(input_key))
                                    }
                                }}
                            >
                                <div className='row m-0'>
                                    <div className={formal_name ? 'col-6 p-0' : 'col-12 p-0'}>
                                        {index !== -1 ? (
                                            < >
                                                {name?.substring(0, index)}
                                                <span style={{ color: 'red' }}>{name?.substring(index, index + input_value?.length)}</span>
                                                {name?.substring(index + input_value?.length)}
                                            </>
                                        ) : (
                                            name
                                        )}

                                    </div>
                                    {
                                        formal_name ?
                                            <div className='col-6'>
                                                {formal_name && type === 'Simple' ? `(${formal_name})` : null}
                                            </div> : null
                                    }
                                </div>
                            </Link>
                        );
                    })
                }

            </div>
        </div>
    )
})

export default React.memo(DropdownLayout_1)
