import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { stockCategoryPrimaryList, stockCategorySingleData, stockCategoryUpdate, openModal, closeModal } from '../../../../../redux/actions/action-creator';
import { toast } from 'react-toastify';
import FormData from 'form-data';
import MasterCreationFooter from '../../../../../components/Footer/Master_Footer/MasterCreationFooter';
import Form from '../../../../../components/Form/Form';
import { yes_no_array, nature_of_group_arrya, applicable_not_applicable_array } from '../../../../../components/Dummi_Data';
import { prepareFormData, useFormHandler } from '../../../../../components/Custom_Hooks/useFormHandler';
import useClickOutside from '../../../../../components/Custom_Hooks/useClickOutsSide';
import { useInputAction } from '../../../../../components/Custom_Hooks/useInputAction';
import InputField from '../../../../../components/Input/InputField';
import DropdownLayout_1 from '../../../../../components/Dropdown/DropdownLayout_1';

function StockCatagory({ data_to_update, title, closeModal_sec }) {

  const dispatch = useDispatch();
  const [load, setLoad] = useState(false)
  const [nameInput, setNameInput] = useState(null)

  const { stock_category_under_data = [] } = useSelector(state => state.common);
  const { userData = null } = useSelector(state => state.auth);
  const modal_state = useSelector(state => state.common.modal_state);
  const filteredCategoryData = stock_category_under_data?.filter(item => item.id !== data_to_update?.id) || [];
  const initialState = {
    name: '',
    alias_name: '',
    under: 'Primary',
    stock_category_under_id: 'Primary',
    company_id: userData?.id
  }
  const [data, setData] = useState(initialState);

  useEffect(() => {
    const formData = new FormData();
    formData.append('company_id', userData?.id)
    dispatch(stockCategoryPrimaryList(formData))
  }, [])
  useEffect(() => {
    setLoad(true)
    const formData = new FormData();
    formData.append('id', data_to_update?.id)
    dispatch(stockCategorySingleData(formData)).then((response) => {
      if (response.status) {
        setData({
          name: response.data?.name,
          alias_name: response.data?.alias_name,
          stock_category_under_id: response.data?.under_name?response?.data?.under_name:'Primary',
          under: response.data?.under,
          company_id: userData?.id,
          id: data_to_update?.id
        })
        setLoad(false)
      }
      else {
        toast.warn(response?.message || 'Fail !', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }).catch(error => {
      console.log(error)
      toast.error(error?.Responsemessage || ' Failed!', {
        position: toast.POSITION.TOP_CENTER
      });
    })
  }, [])
  const inputRefs = {
    name: useRef(null),
    alias_name: useRef(null),
    stock_category_under_id: useRef(null),
  };
  const first_ref = inputRefs?.name

  function getCurrentInputRef(name) {
    return inputRefs[name] || null;
  }

  const inputFields = [
    { label: 'Name', name: 'name', type: 'text', isRequired: true },
    { label: '(alise)', name: 'alias_name', type: 'text', isRequired: false },
    { label: 'Under', name: 'stock_category_under_id', type: 'text', isRequired: true },
  ];

  const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  const validations = {
    name: (value) => value.trim() !== "",
    alias_name: (value) => value.trim() !== "",
    stock_category_under_id: (value) => filteredCategoryData.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
  };

  const refs = Object.keys(inputRefs).reduce((acc, name) => {
    acc[name] = {
      onFocus: () => {
        const ref = getCurrentInputRef(name);
        if (ref) {
          setNameInput(ref)
          ref?.current?.setSelectionRange(0, ref?.current?.value?.length)
        };
        if (name === 'stock_category_under_id') {
          dispatch(openModal(name))
        }
      },
      onBlur: (value) => {
        if (name === 'name') {
          if (!value.trim()) {
            getCurrentInputRef(name)?.current?.focus();
          }
        }
        if (name === 'stock_category_under_id') {
          dispatch(closeModal(name))
        }
      },
    };
    return acc;
  }, {});


  const validateForm = () => {
    const requiredFields = [
      { field: 'name', message: 'Required Name Field' },
      { field: 'stock_category_under_id', message: 'Required Under Field' },
    ]

    for (let { field, message } of requiredFields) {
      if (!data?.[field]) {
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setSubmitConfirm('');
        getCurrentInputRef(field)?.current?.focus();
        return false;
      }
    }
    return true;
  };

  // Function to reset form fields-----------------------------------------
  const resetForm = () => {
    setData(initialState)
  };

  const { handleChange, handleFocus, handleBlur, handleKeyDown, setSubmitConfirm, submitConfirm, } = useInputAction({
    data,
    setData,
    validations,
    refs,
    check_dropdown: modal_state?.stock_category_under_id,
    inputRefs
  });

  const { handleSubmit, loading, handleFocusReset } = useFormHandler({
    validateForm,
    prepareFormData: () => prepareFormData(data),
    submitForm: (data) => dispatch(stockCategoryUpdate(data)),
    shortcuts: [
      {
        key: 'a',
        ctrlKey: true,
        action: () => handleSubmit(new Event('submit')),
      },
    ],
    resetForm,
    first_ref: inputRefs?.name,
    closeModal: closeModal_sec,
    submitConfirm,
    setSubmitConfirm
  });

  const handleClickOutside = (event) => {
    if (nameInput.current) {
      event.preventDefault();
      nameInput.current.focus();
    }
  };

  const handleClickInside = (event) => {
    let clickedElement = event.target;
    if (clickedElement?.tagName &&
      (clickedElement?.tagName.toLowerCase() === 'input' ||
        clickedElement?.tagName.toLowerCase() === 'textarea')) {
      clickedElement.focus();
    }
  };

  useClickOutside(nameInput, handleClickOutside, handleClickInside);
  const dropdownConfig = {
    stock_category_under_id: { title: 'List of Stock Category', input_id: 'under', data: [{ id: 'Primary', name: 'Primary' }, ...filteredCategoryData] },
  };


  return (
    <Form
      handleSubmit={handleSubmit}
      handleKeyDown={handleKeyDown}
      submitConfirm={submitConfirm}
      handleFocusReset={handleFocusReset}
      title={title || 'Stock Category Alteration'}
      loading={loading || load}
      col='col-5'
      Footer={MasterCreationFooter}
    >
      <div className='row p-0 ps-2 m-0 mb-1'>
        {inputFields?.map(({ label, name, type, value, ref, isRequired }, index) => (
          <InputField
            key={index}
            lable={label}
            type={type}
            name={name}
            value={data[name]}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleFocus={handleFocus}
            isRequired={isRequired}
            ref={inputRefs[name]}
          />
        ))}
        {Object.entries(dropdownConfig).map(([key, config]) => (
          modal_state?.[key] && (
            <DropdownLayout_1
              key={key}
              title={config.title}
              state={modal_state[key]}
              data={data}
              setData={setData}
              input_value={data[key]}
              input_key={key}
              input_id={config?.input_id}
              rawData={config.data}
              ref={inputRefs[key]}
              inputRefs={inputRefs}
              setSubmitConfirm={setSubmitConfirm}
            />
          )
        ))}
      </div>
    </Form>
  )
}

export default StockCatagory

