import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { stockGroupUpdate, stockGroupList, stockGroupSingleData, openModal, closeModal } from '../../../../../redux/actions/action-creator';
import { toast } from 'react-toastify';
import FormData from 'form-data';
import MasterCreationFooter from '../../../../../components/Footer/Master_Footer/MasterCreationFooter';
import Form from '../../../../../components/Form/Form';
import { yes_no_array, nature_of_group_arrya, applicable_not_applicable_array } from '../../../../../components/Dummi_Data';
import { prepareFormData, useFormHandler } from '../../../../../components/Custom_Hooks/useFormHandler';
import useClickOutside from '../../../../../components/Custom_Hooks/useClickOutsSide';
import { useInputAction } from '../../../../../components/Custom_Hooks/useInputAction';
import InputField from '../../../../../components/Input/InputField';
import DropdownLayout_1 from '../../../../../components/Dropdown/DropdownLayout_1';


const StockGroup = ({ data_to_update, title, closeModal_sec }) => {

  const dispatch = useDispatch();
  const [load, setLoad] = useState(false)
  const [nameInput, setNameInput] = useState(null)

  const { stock_group_data = [] } = useSelector(state => state.common);
  const { userData = null } = useSelector(state => state.auth);
  const modal_state = useSelector(state => state.common.modal_state);
  const filteredStockGroupData = stock_group_data?.filter(item => item.id !== data_to_update?.id) || [];
  const initialState = {
    name: '',
    alias_name: '',
    under: 'Primary',
    stock_group_under_id: 'Primary',
    qty_items_added: '',

    is_gst_applicable: 'Applicable',

    HSN_SAC_details: 'As per Company/Stock Group',
    HSN_SAC: '',
    description: '',

    GST_rate_details: 'As per Company/Stock Group',
    source_details: 'Not Applicable',
    description: '',
    taxability_type: '',
    IGST_rate: '0',
    CGST_rate: '0',
    SGST_UTGST_rate: '0',
    cess_validation: 'Not Applicable',
    cess_rate: '0',
    application_of_reverse: 'No',
    eligible_tax_credit: 'No',
    company_id: userData?.id,
    id: data_to_update?.id
  }
  const [data, setData] = useState(initialState);
  useEffect(() => {
    setLoad(true)
    const formData = new FormData();
    formData.append('id', data_to_update?.id
    )
    dispatch(stockGroupSingleData(formData)).then((response) => {
      if (response.status) {
        console.log("this is response :----", response)
        setData({
          company_id: userData?.id,
          id: data_to_update?.id,
          name: response.data?.name,
          alias_name: response.data?.alias_name,
          under: response.data?.under,
          stock_group_under_id: response.data?.under_name?response?.data?.under_name:'Primary',
          qty_items_added: '',

          is_gst_applicable: 'Applicable',
          HSN_SAC_details: response.data?.HSN_SAC_details,
          HSN_SAC: response.data?.HSN_SAC,
          description: response.data?.description,

          GST_rate_details: response.data?.GST_rate_details,
          source_details: response.data?.source_details,
          taxability_type: response.data?.taxability_type,
          IGST_rate: response.data?.IGST_rate,
          CGST_rate: response.data?.CGST_rate,
          SGST_UTGST_rate: response.data?.SGST_UTGST_rate,
          cess_validation: response.data?.cess_validation,
          cess_rate: response.data?.cess_rate,
          application_of_reverse: response.data?.application_of_reverse,
          eligible_tax_credit: response.data?.eligible_tax_credit,
          // type_of_supply:response.data?.name,
        })
        setLoad(false)
      }
      else {
        toast.warn(response?.message || 'Fail !', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }).catch(error => {
      console.log(error)
      toast.error(error?.Responsemessage || ' Failed!', {
        position: toast.POSITION.TOP_CENTER
      });
    })
  }, [])


  const inputRefs = {
    name: useRef(null),
    alias_name: useRef(null),
    stock_group_under_id: useRef(null),
  };

  function getCurrentInputRef(name) {
    return inputRefs[name] || null;
  }

  const inputFields = [
    { label: 'Name', name: 'name', type: 'text', isRequired: true, },
    { label: '(alise)', name: 'alias_name', type: 'text', isRequired: false, },
    { label: 'under', name: 'stock_group_under_id', type: 'text', isRequired: true, },
  ];

  const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  const validations = {
    name: (value) => value.trim() !== "",
    alias_name: (value) => value.trim() !== "",
    stock_group_under_id: (value) => filteredStockGroupData.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
  };

  const refs = Object.keys(inputRefs).reduce((acc, name) => {
    acc[name] = {
      onFocus: () => {
        const ref = getCurrentInputRef(name);
        if (ref) {
          setNameInput(ref)
          ref?.current?.setSelectionRange(0, ref?.current?.value?.length)
        };
        if (name === 'stock_group_under_id') {
          dispatch(openModal(name))
        }
      },
      onBlur: (value) => {
        if (name === 'name') {
          if (!value.trim()) {
            getCurrentInputRef(name)?.current?.focus();
          }
        }
        if (name === 'stock_group_under_id') {
          dispatch(closeModal(name))
        }
      },
    };
    return acc;
  }, {});


  const validateForm = () => {
    const requiredFields = [
      { field: 'name', message: 'Required Name Field' },
      { field: 'stock_group_under_id', message: 'Required Under Field' },
    ]

    for (let { field, message } of requiredFields) {
      if (!data?.[field]) {
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setSubmitConfirm('');
        getCurrentInputRef(field)?.current?.focus();
        return false;
      }
    }
    return true;
  };

  // Function to reset form fields-----------------------------------------
  const resetForm = () => {
    setData(initialState)
  };

  const { handleChange, handleFocus, handleBlur, handleKeyDown, setSubmitConfirm, submitConfirm } = useInputAction({
    data,
    setData,
    validations,
    refs,
    check_dropdown: modal_state?.stock_group_under_id,
    inputRefs,
  });

  const { handleSubmit, loading, handleFocusReset } = useFormHandler({
    validateForm,
    prepareFormData: () => prepareFormData(data),
    submitForm: (data) => dispatch(stockGroupUpdate(data)),
    shortcuts: [
      {
        key: 'a',
        ctrlKey: true,
        action: () => handleSubmit(new Event('submit')),
      },
    ],
    resetForm,
    first_ref: inputRefs?.name,
    closeModal: closeModal_sec,
    setSubmitConfirm,
    submitConfirm
  });
  useEffect(() => {
    const formData = new FormData();
    formData.append('company_id', userData?.id)
    dispatch(stockGroupList(formData))
  }, [submitConfirm])

  const handleClickOutside = (event) => {
    if (nameInput.current) {
      event.preventDefault();
      nameInput.current.focus();
    }
  };

  const handleClickInside = (event) => {
    let clickedElement = event.target;
    if (clickedElement?.tagName &&
      (clickedElement?.tagName.toLowerCase() === 'input' ||
        clickedElement?.tagName.toLowerCase() === 'textarea')) {
      clickedElement.focus();
    }
  };
  useClickOutside(nameInput, handleClickOutside, handleClickInside);
  return (
    <Form
      handleSubmit={handleSubmit}
      handleKeyDown={handleKeyDown}
      submitConfirm={submitConfirm}
      handleFocusReset={handleFocusReset}
      title={title || 'Stock Group Creation'}
      loading={loading || load}
      col='col-5'
      Footer={MasterCreationFooter}
    >
      <div className='row p-0 ps-2 m-0 mb-1'>
        {inputFields?.map(({ label, name, type, value, ref, isRequired }, index) => (
          <InputField
            key={index}
            lable={label}
            type={type}
            name={name}
            value={data[name]}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleFocus={handleFocus}
            isRequired={isRequired}
            ref={inputRefs[name]}
          />
        ))}
        {
          modal_state?.stock_group_under_id ?
            <DropdownLayout_1
              title='List of Stock Group'
              state={modal_state?.stock_group_under_id}
              data={data}
              setData={setData}
              input_value={data?.stock_group_under_id}
              input_key='stock_group_under_id'
              input_id='under'
              rawData={filteredStockGroupData}
              ref={inputRefs?.stock_group_under_id}
              inputRefs={inputRefs}
              setSubmitConfirm={setSubmitConfirm}
            /> : null}
      </div>
    </Form>
  )
}

export default StockGroup
