import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormData from 'form-data';
import { groupListCreate, ledgerUpdate, ledgerSingleData, openModal, closeModal } from '../../../../../redux/actions/action-creator';
import MasterCreationFooter from '../../../../../components/Footer/Master_Footer/MasterCreationFooter';
import InputField from '../../../../../components/Input/InputField';
import DropdownLayout_1 from '../../../../../components/Dropdown/DropdownLayout_1';
import Form from '../../../../../components/Form/Form';
import useClickOutside from '../../../../../components/Custom_Hooks/useClickOutsSide';
import { prepareFormData, useFormHandler } from '../../../../../components/Custom_Hooks/useFormHandler';
import { useInputActionLedger } from '../../../../../components/Custom_Hooks/useInputActionLedger';
import useData from '../../../../../components/Custom_Hooks/useData';
import { toast } from 'react-toastify';
import { statesList } from '../../../../../components/Date_Formate_Utilitiy/Index';


const typesOfLederList = [{ id: 1, name: 'Not Applicable' }, { id: 2, name: 'Invoice Rounding' }]
const registrationType = [{ id: 1, name: 'Unkown' }, { id: 2, name: 'Composition' }, { id: 3, name: 'Regular' }, { id: 4, name: 'Unregistered' }]
const listOfMethod = [{ id: 1, name: 'Not Applicable' }, { id: 2, name: 'Appropriate by Qty' }, { id: 3, name: 'Appropriate by Value' }]
const gst_applicability_array =
  [
    { name: 'Applicable' },
    { name: 'Not Applicable' },
  ]
const yes_no_array =
  [
    { name: 'Yes' },
    { name: 'No' },
  ]
const taxability_array =
  [
    { name: 'Exempt' },
    { name: 'Nill Rated' },
    { name: 'None-Gst' },
    { name: 'Taxable' },
  ]
const hsn_detail_gst_rate_array =
  [
    { name: 'As per Company/Stock Group' },
    { name: 'Specify Details Here' },
    { name: 'Specify in Voucher' },
  ]
const type_of_supply_array =
  [
    { name: 'Capital Goods' },
    { name: 'Goods' },
    { name: 'Services' },
  ]
const rounding_method_array =
  [
    { name: 'Downward Rounding' },
    { name: 'Normal Rounding' },
    { name: 'Upward Rounding' },
  ]


const Ledger = ({ data_to_update, title, closeModal_sec }) => {
  const dispatch = useDispatch();
  const { userData = null } = useSelector(state => state.auth);
  const { group_all_list = [] } = useSelector(state => state.common);
  const modal_state = useSelector(state => state.common.modal_state);
  const [nameInput, setNameInput] = useState(null)
  const [load, setLoad] = useState(false)

  const initialState = {
    company_id: userData?.id,
    account_no: '',
    ledger_name: '',
    alise_name: '',
    group_id: userData?.group_data[1]?.id,
    under_id: userData?.group_data[1]?.name,

    maintain_balance_bill_by_bill: 'No',//done
    default_credit_period: '',//done
    specify_credit_limit: '',//done
    inventory_values_are_affected: 'No',//done
    type_of_ledger: 'Not Applicable',//done
    rounding_method: '',//done
    rounding_limit: '',//done

    effective_date_for_reconciliation: '',//done
    set_od_limit: '',//done
    ac_holders_name: '',//done
    ac_no: '',//done
    ifs_code: '',//done
    swift_code: '',//done
    bank_name: 'Not Applicable',//done
    branch: '',//done
    set_alter_range_for_checque_books: 'No',//done
    enable_check_printing: 'No',//done
    set_alter_cheque_printing_configuration: 'No',//done
    use_as_notional_bank_for_post_dated_vouchers: 'No',//done

    behave_as_duties_and_taxes_ledger: '',//no
    type_of_duty_tax: '',//no
    tax_type: '',//no
    valuation_type: '',//done
    rate_per_unit: '',//done
    percentage_of_calculation: '',//done
    gst_applicability: 'Not Applicable',//done
    set_alter_gst_details: '',//no
    include_in_assessable_value_calculation: 'Not Applicable',//done
    hsn_sac_details: '',//done
    source_of_details: '',//done
    hsn_sac: '',//done
    description: '',//done
    gst_rate_details: '',//done
    taxability_type: '',//done
    nature_of_transaction: '',//dpne
    igst_rate: '',//done
    cgst_rate: '',//done
    sgst_utgst_rate: '',//done
    cess_valution_type: '',//done
    cess_rate: '',//done
    applicable_for_reverse_charge: '',//done
    eligible_for_input_tax_credit: '',//done
    type_of_supply: '',//done
    check_for_credit_days_duriing_voucher_entry: '',//done
    method_of_appropriation_to_allocate_additional_consts_in_purchas: 'Not Applicable',//done

    mailing_name: '',//done
    address: '',//done
    city: '',
    state: 'Madhya Pradesh',//done
    country: 'India',//done
    pincode: '',//done
    mobile_no: '',//done
    email: '',//done
    provide_contact_details: 'No',//done
    provide_bank_details: 'No',//done
    pan_it_no: '',//done
    aadhaar: '',//aadhar
    transport_detail: '',//done

    registration_type: '',//done
    assessee_of_other_territory: '',//done
    gst_in_uin: '',//done
    use_ledger_as_common_party: '',//done
    set_alter_additional_gst_details: '',//done
    ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation: '',//done

    opening_balance: '',//done
    type: '',//done

    override_credit_limit_using_post_dated_transaction: '',
    appropriate_to: '',
    method_of_calculation: '',
    classification: '',

    group_slug: userData?.group_data[1]?.group_slug,
    nature_of_group: userData?.group_data[1]?.nature_of_group,
    id: data_to_update?.id
  }

  const { data, setData } = useData(initialState);
  const inputRefs = {
    // account_no: useRef(null),
    ledger_name: useRef(null),
    alise_name: useRef(null),
    under_id: useRef(null),
    maintain_balance_bill_by_bill: useRef(null),
    default_credit_period: useRef(null),
    check_for_credit_days_duriing_voucher_entry: useRef(null),
    specify_credit_limit: useRef(null),

    type_of_ledger: useRef(null),
    rounding_method: useRef(null),
    rounding_limit: useRef(null),
    // inventory_values_are_affected: useRef(null),
    effective_date_for_reconciliation: useRef(null),
    set_od_limit: useRef(null),
    ac_holders_name: useRef(null),
    ac_no: useRef(null),
    ifs_code: useRef(null),
    swift_code: useRef(null),
    bank_name: useRef(null),
    branch: useRef(null),
    set_alter_range_for_checque_books: useRef(null),
    enable_check_printing: useRef(null),
    set_alter_cheque_printing_configuration: useRef(null),
    use_as_notional_bank_for_post_dated_vouchers: useRef(null),
    // behave_as_duties_and_taxes_ledger: useRef(null),
    // type_of_duty_tax: useRef(null),
    // tax_type: useRef(null),
    // valuation_type: useRef(null),
    // rate_per_unit: useRef(null),
    // percentage_of_calculation: useRef(null),
    // gst_applicability: useRef(null),
    // set_alter_gst_details: useRef(null),
    // include_in_assessable_value_calculation: useRef(null),
    // hsn_sac_details: useRef(null),
    // source_of_details: useRef(null),
    // hsn_sac: useRef(null),
    // description: useRef(null),
    // gst_rate_details: useRef(null),
    // taxability_type: useRef(null),
    // nature_of_transaction: useRef(null),
    // igst_rate: useRef(null),
    // cgst_rate: useRef(null),
    // sgst_utgst_rate: useRef(null),
    // cess_valution_type: useRef(null),
    // cess_rate: useRef(null),
    // applicable_for_reverse_charge: useRef(null),
    // eligible_for_input_tax_credit: useRef(null),
    // type_of_supply: useRef(null),
    // method_of_appropriation_to_allocate_additional_consts_in_purchas: useRef(null),

    mailing_name: useRef(null),
    address: useRef(null),
    city: useRef(null),
    state: useRef(null),
    country: useRef(null),
    pincode: useRef(null),
    email: useRef(null),
    mobile_no: useRef(null),
    provide_contact_details: useRef(null),
    provide_bank_details: useRef(null),
    aadhaar: useRef(null),
    transport_detail: useRef(null),
    pan_it_no: useRef(null),

    // registration_type: useRef(null),
    // assessee_of_other_territory: useRef(null),
    // gst_in_uin: useRef(null),
    // use_ledger_as_common_party: useRef(null),
    // set_alter_additional_gst_details: useRef(null),
    // ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation: useRef(null),

    opening_balance: useRef(null),
    type: useRef(null),
  };

  useEffect(() => {
    const formdata = new FormData();
    formdata.append('company_id', userData?.id)
    dispatch(groupListCreate(formdata))
  }, []);

  useEffect(() => {
    setLoad(true)
    const formData = new FormData();
    formData.append('id', data_to_update?.id)
    dispatch(ledgerSingleData(formData)).then((response) => {
      if (response.status) {
        setData({
          company_id: userData?.id,
          id: data_to_update?.id,
          account_no: '',
          ledger_name: response.data.ledger_name,
          alise_name: response.data.alise_name,
          group_id: response.data.group_id,
          under_id: response.data.group_name,

          maintain_balance_bill_by_bill: response.data.maintain_balance_bill_by_bill,
          default_credit_period: response.data.default_credit_period,
          specify_credit_limit: response.data.specify_credit_limit,
          inventory_values_are_affected: response.data.inventory_values_are_affected,
          type_of_ledger: response?.data?.type_of_ledger,
          rounding_method: response.data.rounding_method,
          rounding_limit: response.data.rounding_limit,

          effective_date_for_reconciliation: response.data.effective_date_for_reconciliation,
          set_od_limit: response.data.set_od_limit,
          ac_holders_name: response.data.ac_holders_name,
          ac_no: response.data.ac_no,
          ifs_code: response.data.ifs_code,
          swift_code: response.data.swift_code,
          bank_name: response.data.bank_name,
          branch: response.data.branch,
          set_alter_range_for_checque_books: response.data.set_alter_range_for_checque_books,
          enable_check_printing: response.data.enable_check_printing,
          set_alter_cheque_printing_configuration: response.data.set_alter_cheque_printing_configuration,
          use_as_notional_bank_for_post_dated_vouchers: response.data.use_as_notional_bank_for_post_dated_vouchers,

          behave_as_duties_and_taxes_ledger: '',
          type_of_duty_tax: '',
          tax_type: '',
          valuation_type: response.data.valuation_type,
          rate_per_unit: response.data.rate_per_unit,
          percentage_of_calculation: response.data.percentage_of_calculation,
          gst_applicability: response?.data?.gst_applicability,
          set_alter_gst_details: response.data.set_alter_additional_gst_details,
          include_in_assessable_value_calculation: response?.data?.include_in_assessable_value_calculation,
          hsn_sac_details: response.data.hsn_sac_details,
          source_of_details: response.data.source_of_details,
          hsn_sac: response.data.hsn_sac,
          description: response.data.description,
          gst_rate_details: response.data.gst_rate_details,
          taxability_type: response.data.taxability_type,
          nature_of_transaction: response.data.nature_of_transaction,
          igst_rate: response.data.igst_rate,
          cgst_rate: response.data.cgst_rate,
          sgst_utgst_rate: response.data.sgst_utgst_rate,
          cess_valution_type: response.data.cess_valution_type,
          cess_rate: response.data.cess_rate,
          applicable_for_reverse_charge: response.data.applicable_for_reverse_charge,
          eligible_for_input_tax_credit: response.data.eligible_for_input_tax_credit,
          type_of_supply: response.data.type_of_supply,
          check_for_credit_days_duriing_voucher_entry: response.data.check_for_credit_days_duriing_voucher_entry,
          method_of_appropriation_to_allocate_additional_consts_in_purchas: response?.data?.method_of_appropriation_to_allocate_additional_consts_in_purchas,

          mailing_name: response.data.mailing_name,
          address: response.data.address,
          state: response.data.state,
          country: response?.data?.country,
          pincode: response.data.pincode,
          mobile_no: response.data.mobile_no,
          email: response.data.email,
          provide_contact_details: response.data.provide_contact_details,
          provide_bank_details: response.data.provide_bank_details,
          pan_it_no: response.data.pan_it_no,
          aadhaar: response.data.aadhaar,
          transport_detail: response.data.transport_detail,

          registration_type: response.data.registration_type,
          assessee_of_other_territory: response.data.assessee_of_other_territory,
          gst_in_uin: response.data.gst_in_uin,
          use_ledger_as_common_party: response.data.use_ledger_as_common_party,
          set_alter_additional_gst_details: response.data.set_alter_additional_gst_details,
          ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation: response.data.ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation,

          override_credit_limit_using_post_dated_transaction: '',
          appropriate_to: '',
          method_of_calculation: '',
          classification: '',

          group_slug: response?.data?.group_slug,
          nature_of_group: response?.data?.nature_of_group,

          opening_balance: parseFloat(response.data.opening_balance) > 0 ? response.data.opening_balance : '',
          type: response.data.type

        })

        setLoad(false)
      }
      else {
        toast.warn(response?.message || 'Fail !', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }).catch(error => {
      toast.error(error?.message || ' Failed!', {
        position: toast.POSITION.TOP_CENTER
      });
    })
  }, [])

  const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();

  const validations = useMemo(() => ({
    ledger_name: (value) => value.trim() !== "",
    alise_name: (value) => value.trim() !== "",
    mailing_name: (value) => value.trim() !== "",
    address: (value) => value.trim() !== "",
    city: (value) => value.trim() !== "",
    under_id: (value) => group_all_list.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    maintain_balance_bill_by_bill: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    check_for_credit_days_duriing_voucher_entry: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    specify_credit_limit: (value) => (/^\d+$/).test(value),
    type_of_ledger: (value) => typesOfLederList.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    rounding_method: (value) => rounding_method_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    rounding_limit: (value) => (/^\d+$/).test(value),
    set_alter_range_for_checque_books: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    enable_check_printing: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    set_alter_cheque_printing_configuration: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    use_as_notional_bank_for_post_dated_vouchers: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    state: (value) => statesList.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    pincode: (value) => (/^\d{1,6}$/).test(value),
    mobile_no: (value) => (/^\d{1,10}$/).test(value),
    provide_contact_details: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    provide_bank_details: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    opening_balance: (value) => (/^\d+$/).test(value),
  }), [data]);

  const refs = Object.keys(inputRefs).reduce((acc, name) => {
    acc[name] = {
      onFocus: () => {
        const ref = inputRefs[name];
        if (ref) {
          setNameInput(ref)
          ref?.current?.setSelectionRange(0, ref?.current?.value?.length)
        };
        if ([
          'under_id',
          'maintain_balance_bill_by_bill',
          'check_for_credit_days_duriing_voucher_entry',
          'set_alter_range_for_checque_books',
          'enable_check_printing',
          'set_alter_cheque_printing_configuration',
          'use_as_notional_bank_for_post_dated_vouchers',
          'state',
          'provide_contact_details',
          'provide_bank_details'].includes(name)) {
          if (name) {
            dispatch(openModal(name));
          }
        }
      },
      onBlur: (value) => {
        if (name === 'ledger_name') {
          if (!value.trim()) {
            inputRefs[name]?.current?.focus();
          }
        }
        if ([
          'under_id',
          'maintain_balance_bill_by_bill',
          'check_for_credit_days_duriing_voucher_entry',
          'set_alter_range_for_checque_books',
          'enable_check_printing',
          'set_alter_cheque_printing_configuration',
          'use_as_notional_bank_for_post_dated_vouchers',
          'state',
          'provide_contact_details',
          'provide_bank_details'].includes(name)) {
          if (name) {
            dispatch(closeModal(name));
          }
        }
      },
    };
    return acc;
  }, {})

  const check_dropdown = modal_state?.under_id || modal_state?.maintain_balance_bill_by_bill || modal_state?.check_for_credit_days_duriing_voucher_entry || modal_state?.set_alter_range_for_checque_books || modal_state?.enable_check_printing || modal_state?.set_alter_cheque_printing_configuration || modal_state?.use_as_notional_bank_for_post_dated_vouchers || modal_state?.state || modal_state?.provide_bank_details || modal_state?.provide_contact_details
  const { handleChange, handleFocus, handleBlur, handleKeyDown, setSubmitConfirm, submitConfirm } = useInputActionLedger({
    data,
    setData,
    validations,
    refs,
    check_dropdown,
    inputRefs
  });

  const validateForm = () => {

    const requiredFields = [
      { field: 'ledger_name', message: 'Required ledger Name Field' },
      { field: 'under_id', message: 'Required Under Field' },
      { field: 'group_id', message: 'Required under' },
      { field: 'company_id', message: 'Required company id' },
    ]
    for (let { field, message } of requiredFields) {
      if (!data?.[field]) {
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setSubmitConfirm('');
        inputRefs[field]?.current?.focus();
        return false;
      }
    }
    return true;
  };
  // Function to reset form fields-----------------------------------------
  const resetForm = () => {
    setData((prev) => {
      const updatedState = { ...initialState };
      updatedState['under_id'] = prev.under_id;
      updatedState['group_id'] = prev.group_id;
      updatedState['group_slug'] = prev.group_slug;
      updatedState['nature_of_group'] = prev.nature_of_group;
      return updatedState;
    });
  };

  const { handleSubmit, loading, handleFocusReset } = useFormHandler({
    validateForm,
    prepareFormData: () => prepareFormData(data),
    submitForm: (data) => dispatch(ledgerUpdate(data)),
    shortcuts: [
      {
        key: 'a',
        ctrlKey: true,
        action: () => handleSubmit(new Event('submit')),
      },
    ],
    resetForm,
    first_ref: inputRefs?.ledger_name,
    closeModal: closeModal_sec,
    setSubmitConfirm,
    submitConfirm
  });

  const handleClickOutside = (event) => {
    if (nameInput.current) {
      event.preventDefault();
      nameInput.current.focus();
    }
  };

  const handleClickInside = (event) => {
    let clickedElement = event.target;
    if (clickedElement?.tagName &&
      (clickedElement?.tagName.toLowerCase() === 'input' ||
        clickedElement?.tagName.toLowerCase() === 'textarea')) {
      clickedElement.focus();
    }
  };

  useClickOutside(nameInput, handleClickOutside, handleClickInside);

  const inputFields = {
    show: [
      // { label: 'Account No', name: 'account_no', type: 'text', isRequired: false, },
      { label: 'Name', name: 'ledger_name', type: 'text', isRequired: true, },
      { label: '(alise)', name: 'alise_name', type: 'text', isRequired: false, },
      { label: 'Under', name: 'under_id', type: 'text', isRequired: true, },
    ],
    condition1: [
      // ['capital-account', 'reserves-surplus', 'current-assets', 'depositsasset', 'current-liabilities', 'direct-expenses', 'loansliability', 'investments', 'provisions', 'misc-expenses', 'secured-loans', 'unsecured-loans', 'suspense-ac', 'loans-advance-asset', 'direct-incomes'].includes(data?.group_slug)
      {
        label: 'Maintain balance bill-by-bill',
        name: 'maintain_balance_bill_by_bill',
        type: 'text',
        isRequired: false,
        // isDrop: true,
        state: modal_state?.maintain_balance_bill_by_bill,
      },
      ...(data?.maintain_balance_bill_by_bill === 'Yes'
        ? [
          {
            label: 'Default Credit Period',
            name: 'default_credit_period',
            type: 'text',
            isRequired: false,
            isDrop: false,
          },
        ]
        : []),
    ],
    condition2: [
      // ['branchdivisions', 'sundry-creditors', 'sundry-debtors'].includes(data?.group_slug)
      {
        label: 'Maintain balance bill-by-bill',
        name: 'maintain_balance_bill_by_bill',
        type: 'text',
        isRequired: false,
        isDrop: true,
        state: modal_state?.maintain_balance_bill_by_bill,
      },
      ...(data?.maintain_balance_bill_by_bill === 'Yes'
        ? [
          {
            label: 'Default Credit Period',
            name: 'default_credit_period',
            type: 'text',
            isRequired: false,
            isDrop: false,
          },
          {
            label: 'Check for Credit Days during Voucher Entry',
            name: 'check_for_credit_days_duriing_voucher_entry',
            type: 'text',
            isRequired: false,
            isDrop: true,
            state: modal_state?.check_for_credit_days_duriing_voucher_entry,

          },
        ]
        : []),
      {
        label: 'Specify Credit Limit',
        name: 'specify_credit_limit',
        type: 'text',
        isRequired: false,
        isDrop: false,
      },
    ],
    condition3: [
      // ['direct-expenses', 'direct-incomes', 'indirect-expenses', 'indirect-incomes'].includes(data?.group_slug)
      {
        label: 'Maintain balance bill-by-bill',
        name: 'maintain_balance_bill_by_bill',
        type: 'text',
        isRequired: false,
        // isDrop: true,
        state: modal_state?.maintain_balance_bill_by_bill,
      },
      ...(data?.maintain_balance_bill_by_bill === 'Yes'
        ? [
          {
            label: 'Default Credit Period',
            name: 'default_credit_period',
            type: 'text',
            isRequired: false,
            isDrop: false,
          },
        ]
        : []),
      {
        label: 'Type of Ledger',
        name: 'type_of_ledger',
        type: 'text',
        isRequired: false,
        isDrop: true,
      },
      ...(data?.type_of_ledger === 'Invoice Rounding'
        ? [
          {
            label: 'Rounding Method',
            name: 'rounding_method',
            type: 'text',
            isRequired: false,
            isDrop: true,
          },
          ...(data?.rounding_method === 'Downward Rounding' ||
            data?.rounding_method === 'Normal Rounding' ||
            data?.rounding_method === 'Upward Rounding'
            ? [
              {
                label: 'Rounding Limit',
                name: 'rounding_limit',
                type: 'text',
                isRequired: false,
                isDrop: false,
              },
            ]
            : []),
        ]
        : []),
    ],
    condition4: [
      // ['bank-account', 'bank-od-ac'].includes(data?.group_slug)
      {

        label: 'Effective Date for Reconciliation',
        name: 'effective_date_for_reconciliation',
        type: 'text',
        isRequired: false,
      },
      ...(data?.group_slug === 'bank-od-ac' ? [{
        label: 'Set OD Limit',
        name: 'set_od_limit',
        type: 'text',
        isRequired: false,
      }] : []),
      {
        label: 'A/c Holders Name',
        name: 'ac_holders_name',
        type: 'text',
        isRequired: false,
        isDrop: false
      },
      {
        label: 'A/c No.',
        name: 'ac_no',
        type: 'text',
        isRequired: false,
        isDrop: false,
      },
      {
        label: 'IFSC Code',
        name: 'ifs_code',
        type: 'text',
        isRequired: true,
        isDrop: false,
      },
      {
        label: 'SWIFT Code',
        name: 'swift_code',
        type: 'text',
        isRequired: false,
        isDrop: false,
      },
      {
        label: 'Bank Name',
        name: 'bank_name',
        type: 'text',
        isRequired: false,
        isDrop: false,
      },
      {
        label: 'branch',
        name: 'branch',
        type: 'text',
        isRequired: false,
        isDrop: false,
      },
      {
        label: 'Set/Alter range for Cheque Books',
        name: 'set_alter_range_for_checque_books',
        type: 'text',
        isRequired: false,
        isDrop: true,
        state: modal_state?.set_alter_range_for_checque_books,
      },
      {
        label: 'Enable Cheque Printing',
        name: 'enable_check_printing',
        type: 'text',
        isRequired: false,
        isDrop: true,
        state: modal_state?.enable_check_printing,
      },
      ...(data?.enable_check_printing === 'Yes'
        ? [
          {
            label: 'Set/Alter Cheque Printing configuration',
            name: 'set_alter_cheque_printing_configuration',
            type: 'text',
            isRequired: false,
            isDrop: true,
            state: modal_state?.set_alter_cheque_printing_configuration,
          },
        ]
        : []),
      {
        label: 'Use as National Bank for Post-Dated Vouchers',
        name: 'use_as_notional_bank_for_post_dated_vouchers',
        type: 'text',
        isRequired: false,
        isDrop: true,
        state: modal_state?.use_as_notional_bank_for_post_dated_vouchers,
      },
    ],



    dutie: [
      ...(
        data?.group_slug === 'duties-taxes'
          ? [
            {
              label: "Type of Duty/Tax",
              name: "type_of_duty_tax",
              type: "text",
              isRequired: false,
              isDrop: true,
              dropdownList: ["GST", "Others"],
            },
            ...(data?.type_of_duty_tax === "GST"
              ? [
                {
                  label: "Tax Type",
                  name: "tax_type",
                  type: "text",
                  isRequired: false,
                  isDrop: true,
                  dropdownList: ["IGST", "CGST", "SGST/UTGST", "Cess"],
                },
              ]
              : []),
            ...(data?.tax_type === "Cess"
              ? [
                {
                  label: "Valuation Type",
                  name: "valuation_type",
                  type: "text",
                  isRequired: false,
                  isDrop: true,
                  dropdownList: ["Any", "Based On Quantity", "Based On Value"],
                },
              ]
              : []),
            ...(data?.valuation_type === "Based On Quantity"
              ? [
                {
                  label: "Rate per Unit",
                  name: "rate_per_unit",
                  type: "text",
                  isRequired: false,
                },
              ]
              : [
                {
                  label: "Percentage of Calculation",
                  name: "percentage_of_calculation",
                  type: "number",
                  isRequired: false,
                },
              ]),
            {
              label: "Rounding Method",
              name: "rounding_method",
              type: "text",
              isRequired: false,
              isDrop: true,
              dropdownList: [
                "Not Applicable",
                "Downward Rounding",
                "Normal Rounding",
                "Upward Rounding",
              ],
              showCondition: data?.percentage_of_calculation || data?.rate_per_unit > 0,
            },
            {
              label: "Rounding Limit",
              name: "rounding_limit",
              type: "text",
              isRequired: false,
              showCondition:
                data?.rounding_method === "Downward Rounding" ||
                data?.rounding_method === "Normal Rounding" ||
                data?.rounding_method === "Upward Rounding",
            },
          ]
          : []),],
    // mailing details ----
    mailing: [
      // ['capital-account', 'current-assets', 'fixed-assets', 'depositsasset', 'current-liabilities', 'bank-account', 'bank-od-ac', 'branchdivisions', 'secured-loans', 'unsecured-loans', 'sundry-creditors', 'sundry-debtors', 'investments', 'loans-advance-asset', 'loansliability'].includes(data?.group_slug)
      {
        label: "Name",
        name: "mailing_name",
        type: "text",
        isRequired: true,
        editable: true,
      },
      {
        label: "Address",
        name: "address",
        type: "text",
        isRequired: false,
      },
      {
        label: "City",
        name: "city",
        type: "text",
        isRequired: false,
      },

      {
        label: "State",
        name: "state",
        type: "text",
        isRequired: false,
        isDrop: true,
      },
      {
        label: "Country",
        name: "country",
        type: "text",
        isRequired: false,
        isDrop: false,
      },

      {
        label: "Pincode",
        name: "pincode",
        type: "text",
        isRequired: false,
      },
      {
        label: "Email",
        name: "email",
        type: "text",
        isRequired: false,
      },
      {
        label: "Mobile No",
        name: "mobile_no",
        type: "text",
        isRequired: false,
      },
      {
        label: "Provide Contact Details",
        name: "provide_contact_details",
        type: "text",
        isRequired: false,
        isDrop: true,
        state: modal_state?.provide_contact_details,
      },
      {
        label: "Provide Bank Details",
        name: "provide_bank_details",
        type: "text",
        isRequired: false,
        isDrop: true,
        state: modal_state?.provide_bank_details,
      },
      {
        label: "Aadhaar No.",
        name: "aadhaar",
        type: "text",
        isRequired: false,
        isDrop: false,
      },
      {
        label: "Transport Detail",
        name: "transport_detail",
        type: "text",
        isRequired: false,
        isDrop: false,
      },

      {
        label: "PAN/IT No.",
        name: "pan_it_no",
        type: "text",
        isRequired: false,
        isDrop: false,
      },
    ],
    opening: [{ label: "Opening Balance", name: "opening_balance", type: "text", value: data?.opening_balance, ref: inputRefs?.opening_balance, isRequired: false, isDrop: false, },
    ]
  }

  const dropdownConfig = {
    under_id: { title: 'List of Group', input_id: 'group_id', data: group_all_list },
    state: { title: 'List of State', data: statesList },
  };
  const renderInputFields = useCallback((condition) =>
    inputFields[condition]?.map(({ label, name, type, isRequired, isDrop, state, setState }, index) => (
      <InputField
        key={index}
        lable={label}
        name={name}
        type={type}
        value={data[name]}
        isRequired={isRequired}
        handleChange={handleChange}
        handleBlur={handleBlur}
        handleFocus={handleFocus}
        ref={inputRefs[name]}
        show_drop={state}
        setDrop_add_show={setState}
        setData={setData}
        data={data}
        isDrop={isDrop}
        refs={refs}
      />
    )), [data, handleChange, handleBlur, handleFocus, inputRefs])

  const conditionMappings = {
    condition1: [
      'capital-account', 'reserves-surplus', 'current-assets', 'depositsasset', 'current-liabilities',
      'loansliability', 'investments', 'provisions',
      'secured-loans', 'unsecured-loans', 'suspense-ac', 'loans-advance-asset',

    ],
    condition2: ['branchdivisions', 'sundry-creditors', 'sundry-debtors'],
    condition3: ['direct-expenses', 'direct-incomes', 'indirect-expenses', 'indirect-incomes', 'misc-expenses'],
    condition4: ['bank-account', 'bank-od-ac']
  };
  const renderCondition = (group_slug) => {
    for (let condition in conditionMappings) {
      if (conditionMappings[condition].includes(group_slug)) {
        return renderInputFields(condition);
      }
    }
    return null;
  };

  return (
    <Form
      handleSubmit={handleSubmit}
      handleKeyDown={handleKeyDown}
      submitConfirm={submitConfirm}
      handleFocusReset={handleFocusReset}
      title={title || 'Ledger Creation'}
      loading={loading || load}
      col='col-12'
      Footer={MasterCreationFooter}
    >
      <div className='row p-0  m-0 mb-1'>
        <div className=' col-md-6 m-0 p-0 ps-2' style={{ height: '18vh' }}>
          {renderInputFields('show')}
          {Object.entries(dropdownConfig).map(([key, config]) => (
            modal_state?.[key] && (
              <DropdownLayout_1
                key={key}
                title={config.title}
                state={modal_state[key]}
                data={data}
                setData={setData}
                input_value={data[key]}
                input_key={key}
                input_id={config?.input_id}
                rawData={config.data}
                ref={inputRefs[key]}
                inputRefs={inputRefs}
                setSubmitConfirm={setSubmitConfirm}
              />
            )
          ))}
        </div>
        <div className='border_2_1px_top'></div>

        <div className='col-md-12 m-0 p-0 ps-2' style={{ height: '60vh' }}>
          <div className='row'>
            <div className=' col-lg-6 pt-1 border_2_1px_right' style={{ height: '60vh' }}>
              {
                renderCondition(data?.group_slug)
              }
            </div>
            <div className='col-md-6'>
              <p className='text-start p-0 m-0 pt-1' style={{ fontWeight: 'bold' }}> <u>Mailing Details</u></p>
              {
                ['capital-account', 'current-assets', 'fixed-assets', 'depositsasset', 'current-liabilities', 'bank-account', 'bank-od-ac', 'branchdivisions', 'secured-loans', 'unsecured-loans', 'sundry-creditors', 'sundry-debtors', 'investments', 'loans-advance-asset', 'loansliability'].includes(data?.group_slug) ?
                  renderInputFields('mailing')
                  : null
              }
            </div>
          </div>
        </div>
        <div className='row border_2_1px_top m-0 p-0 ps-2 pt-1 justify-content-center'>
          <div className='col-lg-4 p-0 m-0'>
            {
              inputFields?.opening?.slice(0, 1)?.map(({ label, name, type, value, ref, isRequired }, index) => {
                return (
                  <InputField
                    key={index}
                    lable={label}
                    type={type}
                    name={name}
                    value={value}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleFocus={handleFocus}
                    isRequired={isRequired}
                    ref={ref}
                  />
                )
              })
            }
          </div>
          <div className='col-lg-1 text-start  p-0 m-0 ps-1'>
            {
              data?.opening_balance ?
                <input
                  style={{ fontSize: 14, width: 30 }}
                  autoCapitalize='true'
                  type='text'
                  autoComplete="off"
                  name='type'
                  id='type'
                  value={data?.type || ""}
                  onKeyUp={handleChange}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  ref={inputRefs?.type}
                /> : null
            }

          </div>
        </div>
      </div>
    </Form>
  )
}
export default Ledger;
