import React, { forwardRef, useRef } from 'react'
import { Link } from 'react-router-dom';
import { useDropdownFilter } from '../Custom_Hooks/useDropdownFilter';
import { useDropdownKeyboardNavigation } from '../Custom_Hooks/useDropdownKeyboardNavigation';
import { useDropdownScroll } from '../Custom_Hooks/useDropdownScroll';
import { flushSync } from 'react-dom';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../redux/actions/action-creator';
import { useDropdownFilterForDynamicInput } from '../Custom_Hooks/useDropdownFilterForDynamicInput';


const DropdownForDynamicInput = forwardRef(({ title, state, input_value, input_key, input_id, rawData, data, data_item, setData, setSubmitConfirm, inputRefs, index }, ref) => {
    const dropdownRef = useRef(null)
    const dispatch = useDispatch();
    const { filteredData } = useDropdownFilterForDynamicInput({
        dataList: rawData,
        searchInput: input_value,
        inputRef: ref,
    });
    const { selectedItemIndex, setSelectedItemIndex } = useDropdownKeyboardNavigation({
        items: filteredData,
        state,
        onItemSelect: (item) => {
            setData((prevData) => {
                const updatedData = { ...prevData };

                if (typeof index !== "number") {
                    // Update top-level fields like `quantity`, `rate`, `per`, etc.
                    updatedData[input_key] = item?.ledger_name || item?.name || item?.symbol;
                } else if (typeof index === "number") {
                    // Update array fields within `data_item`
                    updatedData.data_item = updatedData.data_item.map((item_data, i) =>
                        i === index ? {
                            ...item_data,
                            [input_key]: item?.name,
                            location: item?.id || '' 
                        } : item_data
                    );
                }
                return updatedData;
            });
            dispatch(closeModal(`${input_key}${index}`))
        },
        input_value,
    });

    useDropdownScroll({ dropdownRef, selectedItemIndex });
    return (
        <div style={{width:'20%'}} className={`dropdown-content-list ${state ? 'show-list' : ''}`}>
            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                {title}
            </div>

            <div id='data' ref={dropdownRef} style={{ overflowY: 'auto', maxHeight: '80.5vh' }}>
                {
                    Array.isArray(filteredData) && filteredData?.map((key, number) => {
                        const name = key?.ledger_name || key?.name || key?.symbol;
                        const balance = key?.opening_balance;
                        const id = key?.id;
                        const index = name?.toLowerCase()?.indexOf(input_value?.toLowerCase());
                        return (
                            <Link
                                className={`list font-weight-bold ${number === selectedItemIndex ? 'selected' : ''}`}
                                id='list'
                                key={number}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedItemIndex(number)
                                    if (number === selectedItemIndex) {
                                        const updatedData = {
                                            ...data,
                                            [input_key]: name,
                                        };

                                        if (input_id) {
                                            updatedData[input_id] = id;
                                        }
                                        if (input_id === 'group_id') {
                                            updatedData['slug'] = filteredData[selectedItemIndex]?.slug;
                                            updatedData['nature_of_groups'] = filteredData[selectedItemIndex]?.nature_of_group;

                                        }
                                        // if (item?.opening_balance) {
                                        //     updatedData[`${input_key}_bal`] = item?.opening_balance;  // Set the balance if available
                                        // }
                                        setData(updatedData);
                                        let nextField = null;
                                        if (input_key === "weight") {
                                            nextField = updatedData?.weight === "Available" ? "per_unit" : "set_standard_rate";
                                        } else if (input_key === "provide_behaviour_opt") {
                                            nextField = data?.provide_behaviour_opt === "Yes" ? "ignore_diff_physical" : "quantity";
                                        } else {
                                            // Default sequential navigation
                                            const fields = Object.keys(inputRefs);
                                            const currentIndex = fields.indexOf(input_key);
                                            nextField = fields[currentIndex + 1] || null;
                                        }

                                        if (nextField && inputRefs[nextField]?.current) {
                                            inputRefs[nextField].current.focus();
                                            inputRefs[nextField].current.setSelectionRange(0, inputRefs[nextField].current.value.length);
                                        } else {
                                            setSubmitConfirm('Accept')
                                            inputRefs[input_key]?.current?.blur();
                                        }
                                        dispatch(closeModal(input_key))
                                    }
                                }}
                            >
                                {index !== -1 ? (
                                    < >
                                        {name?.substring(0, index)}
                                        <span style={{ color: 'red' }}>{name?.substring(index, index + input_value?.length)}</span>
                                        {name?.substring(index + input_value?.length)}
                                    </>
                                ) : (
                                    name
                                )}
                            </Link>
                        );
                    })
                }

            </div>
        </div>
    )
})

export default React.memo(DropdownForDynamicInput)
