import React, { forwardRef } from 'react'
import { Eye } from 'react-bootstrap-icons'
import DropdownYesNo from '../Dropdown/DropdownYesNo'
import { yes_no_array } from '../Dummi_Data'
import { useSelector } from 'react-redux'
import { dateFormateShow } from '../Date_Formate_Utilitiy/Index'

const InputField = forwardRef(({ type, lable, name, value, handleChange, handleFocus, handleBlur,handleMouseFocus, isRequired, isDrop, show_drop, setDrop_add_show, data, setData, refs,isDisabled }, ref) => {
      const { userData = null } = useSelector(state => state.auth);
    return (
        <div className='row m-0 p-0' style={{height:'3.5vh'}}>
            <div className={`${name==='opening_balance'?`col-6`:`col-5`} m-0 p-0 text-start text-truncate`}>
                <label className={isDisabled?'text-muted':''} >{lable}{isRequired ? <span className='star_color'>*</span> : null} {name==='opening_balance'?`(${dateFormateShow(userData?.books_being_date)})`:null}</label>
            </div>
            <div className=' m-0 p-0' style={{ width: '2%' }}>
                <label className={isDisabled?'text-muted':''}> :</label>
            </div>
            <div className={`${name==='opening_balance'?`col-5`:`col-6`} p-0 m-0 text-start`} style={{ position: 'relative' }}>
                <input
                    style={{ fontSize: 13,fontWeight:'bold', width: '100%' }}
                    type={type}
                    autoComplete="off"
                    disabled={isDisabled}
                    className={isDisabled?'text-muted':''}
                    autoFocus={name === 'company_name' || name === 'name' || name === 'ledger_name'||name === 'symbol'||name==='first_unit_id'}
                    name={name}
                    id={name}
                    value={value || ""}
                    onKeyUp={handleChange}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    onMouseUp={handleMouseFocus}
                    ref={ref}
                />
                {
                    show_drop && (
                        <DropdownYesNo
                            state={show_drop}
                            setState={setDrop_add_show}
                            data={data}
                            setData={setData}
                            input_value={value}
                            input_key={name}
                            rawData={yes_no_array}
                            ref={ref}
                            refs={refs}
                            // next={next}
                        />)
                }
            </div>
        </div>
    )
})

export default React.memo(InputField)