import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

export const useFormHandler = ({
    validateForm,
    prepareFormData,
    submitForm,
    shortcuts = [],
    resetForm,
    first_ref,
    closeModal,
    setSubmitConfirm,
    submitConfirm
}) => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        try {
            setSubmitConfirm('')
            setLoading(true)
            const formData = prepareFormData();
            const response = await submitForm(formData);
            if (response?.status) {
                toast.success(response?.message || 'Operation successful!', {
                    position: toast.POSITION.TOP_CENTER,
                });
                if (location.pathname === 'signUp') {
                    setTimeout(() => {
                        navigate('/');
                        setLoading(false)
                    }, 3000);
                }

                // if (location.pathname === `/alter_accounting_groupupdate/${id}`) {
                //     navigate('/chart-of-account-accounting-master-group');
                //     setLoading(false)
                // }
                if (
                    location.pathname === '/chart-of-account-accounting-master-group' ||
                    location.pathname === '/chart-of-account-accounting-master-ledger' ||
                    location?.pathname === '/chart-of-account-invmas-stockgroup' ||
                    location?.pathname === '/chart-of-account-invmas-stockcategory' ||
                    location?.pathname === '/chart-of-account-invmas-stockcompany' ||
                    location?.pathname === '/chart-of-account-invmas-godown' ||
                    location?.pathname === '/chart-of-account-invmas-unit') {
                    closeModal()
                }
                if (closeModal) {
                    closeModal();
                }
                resetForm();
                handleFocusReset()
            } else {
                setLoading(false)
                handleFocusReset()
                toast.warn(response?.message || 'Operation failed!', {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } catch (error) {
            setLoading(false)
            console.error(error);
            handleFocusReset()
            toast.error(error?.message || 'An error occurred!', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };
    const handleFocusReset = () => {
        setSubmitConfirm('');
        setLoading(false)
        if (first_ref?.current) {
            first_ref.current.focus();
        }
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            shortcuts.forEach((shortcut) => {
                if (
                    e.key.toLowerCase() === shortcut.key.toLowerCase() &&
                    e.ctrlKey === !!shortcut.ctrlKey &&
                    e.altKey === !!shortcut.altKey
                ) {
                    e.preventDefault();
                    shortcut.action();
                }
            });

            if (submitConfirm === 'Accept') {
                if (e.key.toLowerCase() === 'y') {
                    e.preventDefault();
                    handleSubmit(new Event('submit'));
                } else if (e.key.toLowerCase() === 'n') {
                    e.preventDefault();
                    handleFocusReset();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [submitConfirm, shortcuts]);

    return { handleSubmit, loading, handleFocusReset };
};


export const prepareFormData = (fields) => {
    console.log("this is feild :--", fields)
    const formData = new FormData();
    Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return formData;
};
