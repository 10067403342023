import { useState, useEffect, useCallback, useRef } from "react";
import { currencyFormate, formatInputToCapitalization, removeCurrencyComma } from "../Date_Formate_Utilitiy/Index";
import { flushSync } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal } from "../../redux/actions/action-creator";
import React from "react";

export const useInputActionStockItem = ({ initialState, validations, refs, check_dropdown, inputRefs, dynamicInputRef, dynamicFieldRefs }) => {
    const [submitConfirm, setSubmitConfirm] = useState('');
    const [data, setData] = useState(initialState)
    const dispatch = useDispatch();
    const { godown_data = null } = useSelector(state => state.common);
    const modal_state = useSelector(state => state.common.modal_state);


    const handleItemUpdate = (e, index, key, value) => {
        setData((prevState) => ({
            ...prevState,
            data_item: prevState.data_item.map((item, i) => {
                if (i === index) {
                    const updatedItem = { ...item, [key]: value };
                    return updatedItem;
                }
                return item;
            }),
        }));
        // Calculate total amount from all rows


        if (e.key === "Enter") {
            e.preventDefault();
            const fields = Object.keys(dynamicInputRef?.current);
            const currentIndex = fields.indexOf(`${key}${index}`);
            const targetValue = parseFloat(data?.value) || 0;
            const targetQuantity = parseFloat(data?.quantity) || 0;
            setData((prevState) => {
                const updatedDataItems = prevState.data_item.map((item, i) => {
                    if (i === index) {
                        const updatedItem = { ...item, [key]: value };

                        // Convert necessary values to numbers
                        const quantity = parseFloat(removeCurrencyComma(value)) || 0;
                        const rate = parseFloat(removeCurrencyComma(item.rate)) || 0;
                        const boxQuantity = quantity / parseFloat(data?.where_alternate_unit || 1);

                        // Handle quantity updates
                        if (key === 'quantity') {
                            updatedItem.quantity = `${currencyFormate(quantity)} ${data?.unit_name}`;
                            updatedItem.combination_of_units = data?.alt_unit_name !== 'Not Applicable' ? `${currencyFormate(boxQuantity)} ${data?.alt_unit_name}` : '';
                            updatedItem.per = data?.alt_unit_name !== 'Not Applicable' ? `` : data?.unit_name;
                        }

                        // Handle "per" updates
                        if (key === 'per') {
                            const quantityString = updatedItem?.quantity;
                            const rate = updatedItem?.rate;
                            const quantityNumber = parseFloat(quantityString?.replace(/[^0-9.-]+/g, ''));
                            const boxQuantity = quantityNumber / parseFloat(data?.where_alternate_unit || 1);
                            const rateNumber = parseFloat(rate?.replace(/[^0-9.-]+/g, ''));
                            if (data?.alt_unit_name !== 'Not Applicable') {
                                if (updatedItem.id_unit === 1) {
                                    updatedItem.value = currencyFormate(quantityNumber * rateNumber);
                                } else {
                                    updatedItem.value = currencyFormate(boxQuantity * rateNumber);
                                }
                            } else {
                                updatedItem.value = currencyFormate(quantityNumber * rateNumber);
                            }
                        }

                        // Handle "rate" updates
                        if (key === 'rate') {
                            updatedItem.rate = currencyFormate(value);
                        }

                        // Handle "value" updates
                        if (key === 'value') {
                            const quantityString = updatedItem?.quantity;
                            const rate = updatedItem?.rate;
                            const quantityNumber = parseFloat(quantityString?.replace(/[^0-9.-]+/g, ''));
                            const boxQuantity = quantityNumber / parseFloat(data?.where_alternate_unit || 1);
                            const rateNumber = parseFloat(rate?.replace(/[^0-9.-]+/g, ''));
                            // updatedItem.value = currencyFormate(value);
                            if (updatedItem.id_unit === 1) {
                                const get_rate = parseFloat(removeCurrencyComma(value)) / quantityNumber;
                                updatedItem.rate = currencyFormate(get_rate);
                            } else {
                                const get_rate = parseFloat(removeCurrencyComma(value)) / boxQuantity;
                                updatedItem.rate = currencyFormate(get_rate);
                            }
                        }

                        return updatedItem;
                    }
                    return item;
                });
                const totalQuantity = updatedDataItems.reduce((sum, item) => sum + (parseFloat(removeCurrencyComma(item.quantity)) || 0), 0);
                const totalAmount = updatedDataItems.reduce((sum, item) => sum + (parseFloat(removeCurrencyComma(item.value)) || 0), 0);
                return {
                    ...prevState,
                    data_item: updatedDataItems,
                    total_quantitiy_allocation: totalQuantity,
                    total_amount_allocation: totalAmount,
                };

            });
            if (currentIndex < fields.length - 1) {
                const nextField = fields[currentIndex + 1];
                const nextRef = dynamicInputRef.current[nextField];
                if (nextRef) {
                    nextRef.focus();
                    nextRef.setSelectionRange(0, nextRef.value?.length || 0);
                }
            }
            else {
                const totalQuantity = data?.data_item.reduce((sum, item) => sum + (parseFloat(removeCurrencyComma(item.quantity)) || 0), 0);
                const totalAmount = data?.data_item.reduce((sum, item) => sum + (parseFloat(removeCurrencyComma(item.value)) || 0), 0);
                if (data?.modal_type === 'by_qty') {
                    if (totalQuantity < targetQuantity) {
                        if (index === data?.data_item.length - 1) {
                            addRow(index + 1);
                            setTimeout(() => {
                                const newRowRef = dynamicInputRef.current[`godown_name${index + 1}`];
                                if (newRowRef) {
                                    newRowRef.focus();
                                    newRowRef.setSelectionRange(0, newRowRef.value?.length || 0);
                                }
                            }, 0);
                        } else {
                            const nextRowRef = dynamicInputRef.current[`godown_name${index + 1}`];
                            if (nextRowRef) {
                                nextRowRef.focus();
                                nextRowRef.setSelectionRange(0, nextRowRef.value?.length || 0);
                            }
                        }
                    } else if (totalQuantity > targetQuantity) {
                        if (targetQuantity) {
                            dynamicInputRef.current[`quantity${index}`]?.focus();
                        }
                    } else if (totalQuantity == targetQuantity) {
                        setData((prevState) => {
                            const totalQuantity = prevState?.data_item?.reduce((sum, item) => sum + (parseFloat(removeCurrencyComma(item.quantity)) || 0), 0);
                            const totalAmount = prevState?.data_item?.reduce((sum, item) => sum + (parseFloat(removeCurrencyComma(item.value)) || 0), 0);
                            const getRate = totalAmount / totalQuantity
                            return {
                                ...prevState,
                                quantity: prevState?.total_quantitiy_allocation,
                                rate: getRate,
                                per: data?.unit_name,
                                value: totalAmount,
                            };

                        });

                        dispatch(closeModal('itemwithgodown'))
                        setSubmitConfirm('Accept')
                    }
                } else {
                    if (totalAmount < targetValue) {
                        if (index === data?.data_item.length - 1) {
                            addRow(index + 1);
                            setTimeout(() => {
                                const newRowRef = dynamicInputRef.current[`godown_name${index + 1}`];
                                if (newRowRef) {
                                    newRowRef.focus();
                                    newRowRef.setSelectionRange(0, newRowRef.value?.length || 0);
                                }
                            }, 0);
                        } else {
                            const nextRowRef = dynamicInputRef.current[`godown_name${index + 1}`];
                            if (nextRowRef) {
                                nextRowRef.focus();
                                nextRowRef.setSelectionRange(0, nextRowRef.value?.length || 0);
                            }
                        }
                    } else if (totalAmount > targetValue) {
                        if (targetValue) {
                            dynamicInputRef.current[`value${index}`]?.focus();
                        }
                    } else if (totalAmount == targetValue) {
                        dispatch(closeModal('itemwithgodown'))
                        setSubmitConfirm('Accept')
                    }
                }
            }
        }
    };

    // Handle dynamic inputs
    const handleDynamicFocus = (e, index, field) => {
        // Get the reference key for the current field
        const refKey = `${field}${index}`;

        // Get the input reference
        const ref = dynamicInputRef.current[refKey];
        if (ref) {
            ref.setSelectionRange(0, ref.value?.length || 0);
        }

        // Handle modal opening for specific fields
        if (field === 'godown_name') {
            dispatch(openModal(refKey));
        }
        if (field === 'per') {
            dispatch(openModal(refKey));
        }
    };

    const handleDynamicBlur = (e, index, field) => {
        // Get the reference key for the current field
        const refKey = `${field}${index}`;

        // Handle modal closing for specific fields
        if (field === 'godown_name') {
            dispatch(closeModal(refKey));
        }
        if (field === 'per') {
            dispatch(closeModal(refKey));
        }
    };



    const handleKeyDownItem = (e, rowIndex, field) => {
        const fields = ["godown_name", "quantity", "rate", "per", "value"];
        const currentFieldIndex = fields.indexOf(field);
        // if (e.key === "Enter") {
        //     e.preventDefault();
        //     if (currentFieldIndex < fields.length - 1) {
        //         const nextField = fields[currentFieldIndex + 1];
        //         dynamicInputRef.current[rowIndex]?.[nextField]?.focus();
        //     } else {
        //         if (rowIndex === data?.data_item.length - 1) {
        //             addRow(rowIndex + 1);
        //         } else {
        //             dynamicInputRef.current[rowIndex + 1]?.godown_name?.focus();
        //         }
        //     }
        // }
        if (e.key === "Tab" && e.shiftKey) {
            e.preventDefault();
            if (currentFieldIndex > 0) {
                const prevField = fields[currentFieldIndex - 1];
                dynamicInputRef.current[rowIndex]?.[prevField]?.focus();
            } else if (rowIndex > 0) {
                dynamicInputRef.current[rowIndex - 1]?.value?.focus();
            }
        }
    };

    const addRow = (nextIndex) => {
        const totalAmount = data?.data_item.reduce((sum, item) => {
            return sum + (parseFloat(item.value) || 0);
        }, 0);
        const totalQuantity = data?.data_item.reduce((sum, item) => {
            return sum + (parseFloat(item.quantity) || 0);
        }, 0);
        const newRow = {
            id: '1',
            godown_name: '',
            location: '',
            quantity: data?.quantity ? (data?.quantity - totalQuantity).toString() : '',
            combination_of_units: '',
            rate: '',
            per: '',
            value: data?.value ? (data?.value - totalAmount).toString() : '',
            total_nos_box: '',
            total_no_box_unit: data?.alt_unit_name,
        }
        setData((prevState) => ({
            ...prevState,
            data_item: [...prevState.data_item, newRow]
        }));

        setTimeout(() => {
            dynamicInputRef.current[nextIndex]?.godown?.focus();
        }, 0);
    };
    const handleChange = (e, index) => {
        const { name, value } = e.target;
        if (e.code === "Space") {
            e.preventDefault();
            const cursorPosition = e.target.selectionStart;
            if (cursorPosition === 1) {
                setData({ ...data, [name]: "" });
                return;
            }
        }
        if (e.key === 'Enter') {
            e.preventDefault();
            let nextField = null;
            if (name === 'where_unit') {
                if (parseFloat(value) > 0) {
                    nextField = 'where_alternate_unit'
                } else {
                    nextField = 'where_unit'
                }
            } else if (name === 'where_alternate_unit') {
                if (parseFloat(value) > 0) {
                    nextField = 'default_unit_id'
                } else {
                    nextField = 'where_alternate_unit'
                }
            } else
                if (name === 'quantity') {
                    if (value !== '' && godown_data.length > 1) {
                        dispatch(openModal('itemwithgodown'))
                        nextField = null
                    } else {
                        if (value) {
                            nextField = 'rate'
                        } else {
                            nextField = null;
                            setSubmitConfirm('Accept');
                            inputRefs[name]?.current?.blur();
                        }
                    }
                } else
                    if (name === 'value') {
                        if (value !== '' && godown_data.length > 1) {
                            dispatch(openModal('itemwithgodown'))
                            setData((prevState) => ({
                                ...prevState,
                                quantity: '',
                                modal_type: 'by_vle',
                                data_item: [...prevState.data_item, {
                                    godown_name: godown_data[0]?.name || '',
                                    quantity: value,
                                    rate: '10',
                                    per: prevState?.unit_name || '',
                                    value: value
                                }]
                            }));
                            nextField = null;

                        } else {
                            nextField = null;
                        }
                    }
                    else
                        if (name === "weight") {
                            nextField = value === "Available" ? "per_unit" : "quantity";
                        } else
                            if (name === "unit_name") {
                                const unitKeys = ["first_unit", "second_unit", "third_unit", "fourth_unit", "fifth_unit"];
                                nextField = value === "Not Applicable" ? "value" : "alt_unit_name";
                                if (value !== 'Not Applicable') {
                                    setData((prevState) => ({
                                        ...prevState,
                                        modal_type: 'by_qty',
                                        where_unit_name: [...unitKeys]
                                            .reverse()
                                            .map(key => data?.[`unit_name_${key}`])  // Get actual values
                                            .find(value => value) || data?.unit_name,
                                    }));
                                } else {
                                    setData((prevState) => ({
                                        ...prevState,
                                        modal_type: 'by_vle',
                                        quantity: '',
                                        rate: '',
                                        per: '',
                                    }));
                                }

                            } else
                                if (name === "alt_unit_name") {
                                    const unitKeys = ["first_unit", "second_unit", "third_unit", "fourth_unit", "fifth_unit"];
                                    if (value === "Not Applicable") {
                                        nextField = "weight"
                                    } else {
                                        setData((prevState) => ({
                                            ...prevState,
                                            where_alternate_unit_name: [...unitKeys]
                                                .reverse()
                                                .map(key => data?.[`alt_unit_name_${key}`])
                                                .find(value => value) || data?.alt_unit_name,
                                            ['default_unit_id']: data?.unit_name,
                                            ['default_unit']: data?.unit_id,
                                            ['default_unit_rate_id']: data?.unit_id,
                                            ['default_unit_rate']: data?.unit_name
                                        }));
                                        nextField = 'where_unit'
                                    }
                                    dispatch(closeModal('alt_unit_name'));

                                }
                                else
                                    if (name === "per_alt_unit") {
                                        nextField = "quantity";
                                    } else
                                        if (name === "per_unit") {
                                            nextField = data?.alt_unit_name === "Not Applicable" ? "quantity" : 'per_alt_unit';
                                        }
                                        else if (name === "provide_behaviour_opt") {
                                            nextField = data?.provide_behaviour_opt === "Yes" ? "ignore_diff_physical" : "quantity";
                                        } else {
                                            // Default sequential navigation
                                            const fields = Object.keys(inputRefs);
                                            const currentIndex = fields.indexOf(name);
                                            nextField = fields[currentIndex + 1] || null;
                                        }


            if (nextField && inputRefs[nextField]?.current) {
                inputRefs[nextField].current.focus();
                inputRefs[nextField].current.setSelectionRange(0, inputRefs[nextField].current.value.length);
            } else {
                if (name === 'quantity') {
                    if (value !== '' && godown_data?.length > 1) {
                        const calculatedValue = parseFloat(value) * 10; // Calculate value

                        // First update the data state
                        setData((prevState) => ({
                            ...prevState,
                            quantity: value,
                            value: calculatedValue.toString(),
                            data_item: [{  // Reset data_item with single item
                                godown_name: godown_data[0]?.name || '',
                                quantity: value,
                                rate: '10',
                                per: prevState?.unit_name || '',
                                value: calculatedValue.toString()
                            }]
                        }));
                        setTimeout(() => {
                            dynamicInputRef?.current['godown_name0']?.focus();
                        }, 0);


                    }
                } else if (name === 'value') {
                    if (value !== '' && godown_data?.length > 1) {
                        // First update the data state

                        setTimeout(() => {
                            dynamicInputRef?.current['godown_name0']?.focus();
                        }, 0);
                    } else if (!value) {
                        setSubmitConfirm('Accept');
                        inputRefs[name]?.current?.blur();
                    }
                } else {
                    setSubmitConfirm('Accept');
                    inputRefs[name]?.current?.blur();
                }
            }


        }
        // Apply field-specific validations
        const validation = validations[name];
        if (validation) {
            const isValid = validation(value);
            if (isValid || value === "") {
                setData((prevData) => {
                    const updatedData = { ...prevData };

                    if (typeof index !== "number") {
                        const updatedValue = (name === 'name' || name === 'notes')
                            ? formatInputToCapitalization(value)
                            : value;
                        updatedData[name] = updatedValue;
                    } else if (typeof index === "number") {
                        // Update array fields within `data_item`
                        updatedData.data_item = updatedData.data_item.map((item, i) =>
                            i === index ? { ...item, [name]: value } : item
                        );
                    }

                    return updatedData;
                });


            }
        } else {
            flushSync(setData({ ...data, [name]: value }))

        }
    };

    const handleFocus = (e, index, field) => {
        const { name } = e.target;
        const focusAction = refs[name]?.onFocus;
        if (focusAction) focusAction(index, field);
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        const blurAction = refs[name]?.onBlur;
        if (blurAction) blurAction(value, setData);
    };

    const handleKeyDown = (e) => {
        const { name, value } = e.target;
        if (e.key === "Enter") {
            e.preventDefault();
            // if (!check_dropdown) {
            //     if (name === 'company_name' || name === 'ledger_name') {
            //         flushSync(setData({ ...data, ['mailing_name']: value }))

            //     }
            //     if (name === 'opening_balance') {
            //         if (value) {
            //             flushSync(setData({ ...data, ['type']: ['Liabilities', 'Income']?.includes(data?.nature_of_groups) ? 'Cr' : 'Dr' }))

            //         } else {
            //             flushSync(setData({ ...data, ['type']: '' }))

            //         }
            //     }
            //     console.log(refs)
            //     const nextInputRef = refs?.next;
            //     if (nextInputRef && nextInputRef.current) {
            //         setTimeout(() => {
            //             nextInputRef.current.focus();
            //             nextInputRef.current.setSelectionRange(0, nextInputRef.current.value.length);
            //         }, 50);
            //     } else {
            //         setSubmitConfirm('Accept')
            //         refs[name]?.curr?.current?.blur();
            //     }
            // }


            return;
        }
        if (e.shiftKey && e.key === 'Tab') {
            e.preventDefault();
            e.stopPropagation();
            const prevInputRef = refs[name]?.prev;
            if (prevInputRef && prevInputRef.current) {
                prevInputRef.current.focus();
                prevInputRef.current.setSelectionRange(0, prevInputRef?.current?.value?.length);
            }
            return;
        }
    }



    return {
        data,
        setData,
        handleChange,
        handleFocus,
        handleBlur,
        handleKeyDown,
        submitConfirm,
        setSubmitConfirm,
        handleItemUpdate,
        handleKeyDownItem,
        handleDynamicFocus,
        handleDynamicBlur,
    };
};
