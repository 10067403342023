import { isDisabled } from '@testing-library/user-event/dist/utils'
import React, { forwardRef } from 'react'

const Input = forwardRef(({ type, lable, name, value, handleChange, handleFocus, handleBlur, isRequired, isDrop, show_drop, setDrop_add_show, data, setData, refs,isDisabled }, ref) => {
    return (
        <input
            style={{ fontSize: 13, fontWeight: 'bold', width: '100%',textAlign:'end',paddingRight:5 }}
            type={type}
            autoComplete="off"
            isDisabled={isDisabled}
            autoFocus={name === 'company_name' || name === 'name' || name === 'ledger_name' || name === 'symbol'}
            name={name}
            id={name}
            value={value || ""}
            onKeyUp={handleChange}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            ref={ref}
        />
    )
})

export default Input
