import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  stockCategoryPrimaryList, stockGroupList,
  stockItemCreate, unitsListing, stockCompanyList, godownList, openModal, closeModal

} from '../../../../../redux/actions/action-creator';
import FormData from 'form-data';
import ItemWithGodown from './ItemWithGodown';
import MasterCreationFooter from '../../../../../components/Footer/Master_Footer/MasterCreationFooter';
import InputField from '../../../../../components/Input/InputField';
import Form from '../../../../../components/Form/Form';
import Input from '../../../../../components/Input/Input';
import ModalForm from '../../../../../components/Modal/ModalForm';
import useClickOutside from '../../../../../components/Custom_Hooks/useClickOutsSide';
import { prepareFormData, useFormHandler } from '../../../../../components/Custom_Hooks/useFormHandler';
import { toast } from 'react-toastify';
import DropdownLayout_1 from '../../../../../components/Dropdown/DropdownLayout_1';
import { availlable_array, yes_no_array } from '../../../../../components/Dummi_Data';
import useData from '../../../../../components/Custom_Hooks/useData';
import { useInputActionStockItem } from '../../../../../components/Custom_Hooks/useInputActionStockItem';
import StockGroupCreate from './StockGroup';
import StockCategoryCreate from './StockCatagory';
import StockCompanyCreate from './StockCompany';
import UnitCreate from './Unit';
const StockItem = React.memo(({ title }) => {

  const dispatch = useDispatch();

  // get state data from redux ------------------------------------------------------------------------------------------
  // const { unit_data = null, stock_category_under_data = null, stock_group_data = null, stock_company_data = null, godown_data = null } = useSelector(state => state.common);
  const {
    unit_data = [],
    stock_category_under_data = [],
    stock_group_data = [],
    stock_company_data = [],
    godown_data = []
  } = useSelector(state => state.common);
  const notApplicable = { id: 'Not Applicable', symbol: 'Not Applicable' };
  const notApplicableName = { id: 'Not Applicable', name: 'Not Applicable' };

  // Add "Not Applicable" to each array
  // const updatedUnitData = [notApplicable, ...unit_data];
  const updatedStockGroupData = useMemo(() => [notApplicableName, ...stock_group_data], [stock_group_data]);
  const updatedStockCategoryData = useMemo(() => [notApplicableName, ...stock_category_under_data], [stock_category_under_data]);
  const updatedStockCompanyData = useMemo(() => [notApplicableName, ...stock_company_data], [stock_company_data]);
  const updatedUnitData = useMemo(() => [notApplicable, ...unit_data], [unit_data]);
  const modal_state = useSelector(state => state.common.modal_state);
  const { userData = null } = useSelector(state => state.auth);

  const initialState = {
    company_id: userData?.id,
    name: '',
    alias_name: '',
    part_no: '',
    item_desc: '',
    notes: '',
    set_default_ledger: 'No',
    under_id: 'Primary',
    under: 'Primary',
    stock_cat_id: 'Not Applicable',
    stock_cat: 'Not Applicable',
    stock_company: 'Not Applicable',
    unit_id: 'Not Applicable',
    unit_name: 'Not Applicable',
    unit_name_first_unit: '',
    unit_name_second_unit: '',
    unit_name_third_unit: '',
    unit_name_fourth_unit: '',
    unit_name_fifth_unit: '',
    alt_unit_id: 'Not Applicable',
    alt_unit_name: 'Not Applicable',
    alt_unit_name_first_unit: '',
    alt_unit_name_second_unit: '',
    alt_unit_name_third_unit: '',
    alt_unit_name_fourth_unit: '',
    alt_unit_name_fifth_unit: '',
    where_unit_name: '',
    where_alternate_unit_name: '',
    where_unit: '0',
    where_alternate_unit: '0',
    default_unit: 'Not Applicable',
    default_unit_id: 'Not Applicable',
    default_unit_rate: '',
    default_unit_rate_id: '',
    weight: 'Not Available',
    per_unit: '',
    per_alt_unit: '',

    set_standard_rate: 'No',
    costing_method: '',
    market_valuation: '',
    provide_behaviour_opt: 'No',
    ignore_diff_physical: 'No',
    ignore_negative_balance: 'No',
    treat_sale_manufacture: 'No',
    treat_purchased_consumed: 'No',
    treat_rejection_scrap: 'No',

    data_item: [
      {
        id: '1',
        godown_name: '',
        location: '',
        quantity: '',
        combination_of_units: '',
        rate: '',
        id_unit: '',
        per: '',
        value: '',
        total_nos_box: '',
        total_no_box_unit: '',
      }
    ],
    modal_type: '',
    total_quantitiy_allocation: '',
    total_amount_allocation: '',
    quantity: '',
    rate: '',
    per: '',
    value: '',
    total_nos_box: '',
    total_no_box_unit: '',
    opening_date: userData?.books_being_date,
    opening_balance: '',

    // gst 

    GST_applicable: 'Applicable',
    HSN_SAC_details: 'As per Company/Stock Group',
    source_details: 'Not Applicable',
    HSN_SAC: '',
    description: '',

    GST_rate_details: 'As per Company/Stock Group',
    source_of_details: 'Not Applicable',
    taxability_type: '',
    IGST_rate: '0',
    CGST_rate: '0',
    SGST_UTGST_rate: '0',
    cess_validation: 'Not Applicable',
    cess_rate: '0',
    application_of_reverse: 'No',
    eligible_tax_credit: 'No',
    rate_of_Duty: '',
    type_of_supply: 'Goods',

  }
  // const { data, setData } = useData(initialState);
  const [nameInput, setNameInput] = useState(null)
  const inputRefs = {
    name: useRef(null),
    alias_name: useRef(null),
    part_no: useRef(null),
    item_desc: useRef(null),
    notes: useRef(null),
    set_default_ledger: useRef(null),
    under_id: useRef(null),
    stock_cat: useRef(null),
    stock_company: useRef(null),
    unit_name: useRef(null),
    alt_unit_name: useRef(null),
    where_unit: useRef(null),
    where_alternate_unit: useRef(null),
    default_unit_id: useRef(null),
    default_unit_rate: useRef(null),
    weight: useRef(null),
    per_unit: useRef(null),
    per_alt_unit: useRef(null),

    set_standard_rate: useRef(null),
    costing_method: useRef(null),
    market_valuation: useRef(null),
    provide_behaviour_opt: useRef(null),
    ignore_diff_physical: useRef(null),
    ignore_negative_balance: useRef(null),
    treat_sale_manufacture: useRef(null),
    treat_purchased_consumed: useRef(null),
    treat_rejection_scrap: useRef(null),

    quantity: useRef(null),
    rate: useRef(null),
    per: useRef(null),
    value: useRef(null),

    // gst 

    GST_applicable: useRef(null),
    HSN_SAC_details: useRef(null),
    HSN_SAC: useRef(null),
    description: useRef(null),

    GST_rate_details: useRef(null),
    source_of_details: useRef(null),
    taxability_type: useRef(null),
    IGST_rate: useRef(null),
    CGST_rate: useRef(null),
    SGST_UTGST_rate: useRef(null),
    cess_validation: useRef(null),
    cess_rate: useRef(null),
    application_of_reverse: useRef(null),
    eligible_tax_credit: useRef(null),
    rate_of_Duty: useRef(null),
    type_of_supply: useRef(null),
  };
  const dynamicInputRef = useRef({})

  function getCurrentInputRef(name) {
    return inputRefs[name] || null;
  }

  useEffect(() => {
    const formData = new FormData();
    formData.append('company_id', userData?.id)
    dispatch(unitsListing(formData))
    dispatch(godownList(formData))
    dispatch(stockGroupList(formData))
    dispatch(stockCategoryPrimaryList(formData))
    dispatch(stockCompanyList(formData))
  }, [modal_state?.create_under, modal_state?.create_stock_cat, modal_state?.create_stock_company, modal_state?.create_unit])

  useEffect(() => {
    if (modal_state?.create_under || modal_state?.create_stock_cat || modal_state?.create_stock_company || modal_state?.create_unit) {
      return;
    }
  }, [modal_state?.create_under, modal_state?.create_stock_cat, modal_state?.create_stock_company, modal_state?.create_unit])

  useEffect(() => {
    if (nameInput?.current) {
      nameInput?.current?.focus();
    }
  }, [modal_state?.create_under, modal_state?.create_stock_cat, modal_state?.create_stock_company, modal_state?.create_unit])

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.altKey && event.key === 'c') {
        if (document.activeElement === inputRefs?.under_id?.current) {
          dispatch(openModal('create_under'))
        } else if (document.activeElement === inputRefs?.stock_cat?.current) {
          dispatch(openModal('create_stock_cat'))
        } else if (document.activeElement === inputRefs?.stock_company?.current) {
          dispatch(openModal('create_stock_company'))
        } else if (document.activeElement === inputRefs?.unit_name?.current || document.activeElement === inputRefs?.alt_unit_name?.current) {
          dispatch(openModal('create_unit'))
        }
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);
  const filter_alt_unit = unit_data.filter(unit => {
    const firstUnitValue = inputRefs?.unit_name?.current?.value?.trim();
    if (!firstUnitValue) return true;
    // if (unit.type === "Simple" && unit.symbol === firstUnitValue) return false;
    // if (unit.type === "Compound") {
    const firstUnitWords = firstUnitValue.split(" ").filter(word => isNaN(Number(word)) && word.toLowerCase() !== 'of');
    const unitWords = unit.symbol.split(" ").filter(word => isNaN(Number(word)) && word.toLowerCase() !== 'of');
    const match =
      firstUnitWords.some(word => unitWords.some(unitWord => unitWord.toLowerCase().includes(word.toLowerCase())))
    if (firstUnitWords.every(word => unit.symbol.toLowerCase().includes(word.toLowerCase()))) {
      return false;
    }
    if (match) {
      return false;
    }
    // if (match) return false;
    // }
    return true;
  })
    ;
  const updatedAltUnitData = useMemo(() => [notApplicable, ...filter_alt_unit], [unit_data, inputRefs?.unit_name?.current?.value]);

  const filter_default_unit = unit_data.filter(unit => {
    const firstUnitValue = inputRefs?.default_unit_id?.current?.value?.trim();

    if (!firstUnitValue) return true;
    if (unit.type === "Simple" && unit.symbol === firstUnitValue) return false;
    if (unit.type === "Compound") {
      const firstUnitWords = firstUnitValue.split(" ").filter(word => isNaN(word));
      const unitWords = unit.symbol.split(" ").filter(word => isNaN(word));

      const match = firstUnitWords.every(word => unitWords.includes(word));

      if (match) return false;
    }
    return true;
  });




  const cleanString = (str) => str?.replace(/[^a-zA-Z0-9]/g, "")?.toLowerCase();
  const validations = {
    name: (value) => value.trim() !== "",
    alias_name: (value) => value.trim() !== "",
    part_no: (value) => value.trim() !== "",
    notes: (value) => value.trim() !== "",
    set_default_ledger: (value) => yes_no_array?.some(item => cleanString(item?.name)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
    under_id: (value) => stock_group_data?.some(item => cleanString(item?.name)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
    stock_cat: (value) => updatedStockCategoryData?.some(item => cleanString(item?.name)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
    stock_company: (value) => updatedStockCompanyData?.some(item => cleanString(item?.name)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
    unit_name: (value) => updatedUnitData?.some(item => cleanString(item?.symbol)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
    alt_unit_name: (value) => updatedAltUnitData?.some(item => cleanString(item?.symbol)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
    default_unit_id: (value) => unit_data?.some(item => cleanString(item?.symbol)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
    weight: (value) => availlable_array?.some(item => cleanString(item?.name)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
    set_standard_rate: (value) => yes_no_array?.some(item => cleanString(item?.name)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
    costing_method: (value) => yes_no_array?.some(item => cleanString(item?.name)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
    market_valuation: (value) => yes_no_array?.some(item => cleanString(item?.name)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
    provide_behaviour_opt: (value) => yes_no_array?.some(item => cleanString(item?.name)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
    ignore_diff_physical: (value) => yes_no_array?.some(item => cleanString(item?.name)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
    ignore_negative_balance: (value) => yes_no_array?.some(item => cleanString(item?.name)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
    treat_sale_manufacture: (value) => yes_no_array?.some(item => cleanString(item?.name)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
    treat_purchased_consumed: (value) => yes_no_array?.some(item => cleanString(item?.name)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
    treat_rejection_scrap: (value) => yes_no_array?.some(item => cleanString(item?.name)?.toLowerCase()?.includes(cleanString(value)?.toLowerCase())),
  };

  const refs = Object.keys(inputRefs).reduce((acc, name) => {
    acc[name] = {
      curr: getCurrentInputRef(name),
      onFocus: () => {
        const ref = getCurrentInputRef(name);
        if (ref) {
          setNameInput(ref)
          ref?.current?.setSelectionRange(0, ref?.current?.value?.length)
        };
        if (['under_id', 'stock_cat', 'stock_company', 'unit_name', 'alt_unit_name', 'default_unit_id', 'weight'].includes(name)) {
          if (name) {
            dispatch(openModal(name));
          }
        }
        if (['set_default_ledger', 'set_standard_rate', 'provide_behaviour_opt', 'ignore_diff_physical', 'ignore_negative_balance', 'treat_sale_manufacture', 'treat_purchased_consumed', 'treat_rejection_scrap'].includes(name)) {
          if (name) {
            dispatch(openModal(name));
          }
        }
      },
      onBlur: (value) => {
        if (name === 'name') {
          if (!value.trim()) {
            getCurrentInputRef(name)?.current?.focus();
          }
        }
        if (['under_id', 'stock_cat', 'stock_company', 'unit_name', 'alt_unit_name', 'default_unit_id', 'weight'].includes(name)) {
          if (name) {
            dispatch(closeModal(name));
          }
        }
        if (['set_default_ledger', 'set_standard_rate', 'provide_behaviour_opt', 'ignore_diff_physical', 'ignore_negative_balance', 'treat_sale_manufacture', 'treat_purchased_consumed', 'treat_rejection_scrap'].includes(name)) {
          if (name) {
            dispatch(closeModal(name));
          }
        }
        if (name === 'treat_rejection_scrap') {
          dispatch(closeModal('provide_behaviour_opt'))
        }
      },
    };
    return acc;
  }, {});

  const dynamicFieldRefs = Object.keys(dynamicInputRef?.current).reduce((acc, name) => {
    acc[name] = {
      onFocus: (index, field) => {
        const ref = dynamicInputRef.current[`${field}${index}`];
        if (ref) {
          ref.setSelectionRange(0, ref.value?.length);
          setNameInput(ref)
        }
        if (name === `${'godown_name'}${index}`) {
          dispatch(openModal(`${field}${index}`));
        }
        if (name === `${'per'}${index}`) {
          dispatch(openModal(`${field}${index}`));
        }
      },
      onBlur: (index, field) => {
        if (name === `${'godown_name'}${index}`) {
          alert()
          dispatch(closeModal(`${field}${index}`));
        }
        if (name === `${'per'}${index}`) {
          dispatch(closeModal(`${field}${index}`));
        }
      }
    }
    return acc;
  }, {});

  const { data,
    setData, handleChange, handleFocus, handleBlur, handleKeyDown, setSubmitConfirm, submitConfirm, handleItemUpdate, handleKeyDownItem, handleDynamicFocus,
    handleDynamicBlur, } = useInputActionStockItem({
      initialState,
      validations,
      refs,
      check_dropdown: modal_state?.under_id || modal_state?.stock_cat || modal_state?.stock_company || modal_state?.unit_name || modal_state?.alt_unit_name || modal_state?.weight,
      inputRefs,
      dynamicInputRef,
      dynamicFieldRefs
    });

  const validateForm = () => {

    let requiredFields;
    requiredFields = [
      { field: 'name', message: 'Required Name Field' },
    ];



    for (let { field, message } of requiredFields) {
      if (!data?.[field]) {
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setSubmitConfirm('');
        getCurrentInputRef(field)?.current?.focus();
        return false;
      }
    }
    return true;

  };

  const resetForm = () => {
    setData(initialState)
  };

  const { handleSubmit, loading, handleFocusReset } = useFormHandler({
    validateForm,
    prepareFormData: () => prepareFormData(data),
    submitForm: (data) => dispatch(stockItemCreate(data)),
    shortcuts: [
      {
        key: 'a',
        ctrlKey: true,
        action: () => handleSubmit(new Event('submit')),
      },
    ],
    resetForm,
    first_ref: inputRefs?.name,
    closeModal,
    setSubmitConfirm,
    submitConfirm
  });




  const handleClickOutside = (event) => {
    if (nameInput.current) {
      event.preventDefault();
      nameInput.current.focus();
    }
  };

  const handleClickInside = (event) => {
    let clickedElement = event.target;
    if (clickedElement?.tagName &&
      (clickedElement?.tagName.toLowerCase() === 'input' ||
        clickedElement?.tagName.toLowerCase() === 'textarea')) {
      clickedElement.focus();
    }
  };
  useClickOutside(nameInput, handleClickOutside, handleClickInside);

  const inputFields = [
    { label: 'Name', name: "name", type: "text", isRequired: true },
    { label: '(alias)', name: "alias_name", type: "text", isRequired: false },
    { label: 'Part No.', name: "part_no", type: "text", isRequired: false },
    { label: 'Description', name: "item_desc", type: "text", isRequired: false },
    { label: 'Notes', name: "notes", type: "text", isRequired: false },
    { label: 'Set/modify default ledgers for invoicing', name: "set_default_ledger", type: "text", isRequired: false, isDrop: true, state: modal_state?.set_default_ledger },
    { label: 'Under', name: "under_id", type: "text", isRequired: false },
    { label: 'Category', name: "stock_cat", type: "text", isRequired: false },
    { label: 'Company', name: "stock_company", type: "text", isRequired: false },
    { label: 'Unit', name: "unit_name", type: "text", isRequired: false },
    { label: 'Alternate units', name: "alt_unit_name", type: "text", isRequired: false, isDisabled: data?.unit_name === 'Not Applicable' },
    { label: '', name: "where_unit", type: "text", isRequired: false, isDisabled: data?.unit_name === 'Not Applicable' },
    { label: '', name: "where_alternate_unit", type: "text", isRequired: false, isDisabled: data?.unit_name === 'Not Applicable' },
    { label: 'Default Unit For Quantity', name: "default_unit_id", type: "text", isRequired: false, isDisabled: data?.unit_name === 'Not Applicable' },
    { label: 'Default Unit For Rate ', name: "default_unit_rate", type: "text", isRequired: false, isDisabled: data?.unit_name === 'Not Applicable' },
    { label: 'Weight', name: "weight", type: "text", isRequired: false, isDisabled: data?.unit_name === 'Not Applicable' },
    { label: 'Per Unit', name: "per_unit", type: "text", isRequired: false, isDisabled: data?.unit_name === 'Not Applicable' },
    { label: 'Per Alternate Unit', name: "per_alt_unit", type: "text", isRequired: false, isDisabled: data?.unit_name === 'Not Applicable' },
    { label: 'Set standard rates', name: "set_standard_rate", type: "text", isRequired: false, isDrop: true, state: modal_state?.set_standard_rate, isDisabled: true },
    { label: 'Costing Method', name: "costing_method", type: "text", isRequired: false, isDisabled: true },
    { label: 'Market Valuation Method', name: "market_valuation", type: "text", isRequired: false, isDisabled: true },
    { label: 'Provide Behaviour options', name: "provide_behaviour_opt", type: "text", isRequired: false, isDrop: true, state: modal_state?.provide_behaviour_opt, isDisabled: true },
    { label: 'Ignore difference due to physical counting', name: "ignore_diff_physical", type: "text", isRequired: false, isDrop: true, state: modal_state?.ignore_diff_physical, isDisabled: true },
    { label: 'Ignore negative balances', name: "ignore_negative_balance", type: "text", isRequired: true, isDrop: false, state: modal_state?.ignore_negative_balance, isDisabled: true },
    { label: 'Treat all sales as new manufacture', name: "treat_sale_manufacture", type: "text", isRequired: false, isDrop: true, state: modal_state?.treat_sale_manufacture, isDisabled: true },
    { label: 'Treat all purchases as consumed', name: "treat_purchased_consumed", type: "text", isRequired: false, isDrop: true, state: modal_state?.treat_purchased_consumed, isDisabled: true },
    { label: 'Treat all rejections inward as scrap', name: "treat_rejection_scrap", type: "text", isRequired: false, isDrop: true, state: modal_state?.treat_rejection_scrap, isDisabled: true },
    { label: 'Quantity', name: "quantity", type: "text", isRequired: false, isDisabled: data?.unit_name === 'Not Applicable' },
    { label: 'Rate', name: "rate", type: "text", isRequired: false, isDisabled: data?.unit_name === 'Not Applicable' },
    { label: 'Per', name: "per", type: "text", isRequired: false, isDisabled: data?.unit_name === 'Not Applicable' },
    { label: 'Value', name: "value", type: "text", isRequired: false },
  ];

  const dropdownConfig = {
    under_id: { title: 'List of Stock Group', input_id: 'under', data: stock_group_data },
    stock_cat: { title: 'List of Stock Category', input_id: 'stock_cat_id', data: updatedStockCategoryData },
    stock_company: { title: 'List of Stock Company', data: updatedStockCompanyData },
    unit_name: { title: 'List of Unit', input_id: 'unit_id', data: updatedUnitData },
    alt_unit_name: { title: 'List of Unit', input_id: 'alt_unit_id', data: updatedAltUnitData },
    default_unit_id: { title: 'List of Unit', input_id: 'default_unit', data: [{ id: data?.unit_id, symbol: data?.unit_name }, { id: data?.alt_unit_id, symbol: data?.alt_unit_name }] },
    weight: { title: 'List of Applicable', data: availlable_array },
  };


  const dynamicValidations = {
    godown_name: (value) => value.trim() !== "",
    quantity: (value) => !isNaN(value) && value.trim() !== "",
    rate: (value) => !isNaN(value) && value.trim() !== "",
    // Add other validations as needed
  };
  return (
    <>
      {modal_state?.create_under || modal_state?.create_stock_cat || modal_state?.create_stock_company || modal_state?.create_unit ?
        <>
          {modal_state?.create_under ?
            <div style={{ zIndex: 10000 }}>
              <StockGroupCreate
                title='Stock Group Creation (Secondary)'
                closeModal_sec={() => dispatch(closeModal('create_under'))}
              />
            </div> : null}
          {modal_state?.create_stock_cat ?
            <div style={{ zIndex: 10000 }}>
              <StockCategoryCreate
                title='Stock Category Creation (Secondary)'
                closeModal_sec={() => dispatch(closeModal('create_stock_cat'))}
              />
            </div> : null}
          {modal_state?.create_stock_company ?
            <div style={{ zIndex: 10000 }}>
              <StockCompanyCreate
                title='Stock Company Creation (Secondary)'
                closeModal_sec={() => dispatch(closeModal('create_stock_company'))}
              />
            </div> : null}
          {modal_state?.create_unit ?
            <div style={{ zIndex: 10000 }}>
              <UnitCreate
                title='Unit Creation (Secondary)'
                closeModal_sec={() => dispatch(closeModal('create_unit'))}
              />
            </div> : null}
        </>
        :
        <Form
          handleSubmit={handleSubmit}
          handleKeyDown={handleKeyDown}
          submitConfirm={submitConfirm}
          handleFocusReset={handleFocusReset}
          title={title || 'Stock Item Creation'}
          loading={loading}
          col='col-11'
          Footer={MasterCreationFooter}
        >

          {modal_state?.itemwithgodown ?
            <ItemWithGodown
              data={data}
              data_item={data?.data_item}
              setData={setData}
              handleChange={handleItemUpdate}
              inputRefs={dynamicInputRef}
              handleKeyDownItem={handleKeyDownItem}
              handleDynamicFocus={handleDynamicFocus}
              handleDynamicBlur={handleDynamicBlur}
            /> : null}
          {
            modal_state?.behavior
              ?
              <div style={{ zIndex: 10000 }}>
                <ModalForm
                  data={data}
                  setData={setData}
                  inputFields={inputFields?.slice(22, 27)}
                  inputRefs={inputRefs}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleFocus={handleFocus}
                // closeModal={() => dispatch(closeModal('modal_2'))}
                />
              </div>
              : null
          }
          <div className='row p-0 m-0 mb-1'>
            <div className='col-5 pb-2'>
              {inputFields.slice(0, 3).map(({ label, name, type, isRequired }, index) => (
                <InputField
                  key={index}
                  lable={label}
                  type={type}
                  name={name}
                  value={data[name] || ''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleFocus={handleFocus}
                  isRequired={isRequired}
                  ref={inputRefs[name]}
                />
              ))}
            </div>
            <div className='col-7 pb-2'>
              {inputFields.slice(3, 5).map(({ label, name, type, isRequired }, index) => (
                <InputField
                  key={index}
                  lable={label}
                  type={type}
                  name={name}
                  value={data[name] || ''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleFocus={handleFocus}
                  isRequired={isRequired}
                  ref={inputRefs[name]}
                />
              ))}
              {inputFields.slice(5, 6).map(({ label, name, type, isRequired, isDrop, state }, index) => (
                <InputField
                  key={index}
                  lable={label}
                  type={type}
                  name={name}
                  value={data[name] || ''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleFocus={handleFocus}
                  isRequired={isRequired}
                  ref={inputRefs[name]}
                  inputRefs={inputRefs}
                  show_drop={state}
                  setData={setData}
                  data={data}
                  isDrop={isDrop}

                />
              ))}
            </div>
            <div className='border'></div>
            <div className='col-6 pt-1 border-end'>
              {inputFields.slice(6, 11).map(({ label, name, type, isRequired, isDisabled }, index) => (
                <InputField
                  key={index}
                  lable={label}
                  type={type}
                  name={name}
                  value={data[name] || ''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleFocus={handleFocus}
                  isRequired={isRequired}
                  isDisabled={isDisabled}
                  ref={inputRefs[name]}
                />
              ))}
              {Object.entries(dropdownConfig).map(([key, config]) => (
                modal_state?.[key] && (
                  <DropdownLayout_1
                    key={key}
                    title={config.title}
                    state={modal_state[key]}
                    data={data}
                    setData={setData}
                    input_value={data[key]}
                    input_key={key}
                    input_id={config?.input_id}
                    rawData={config.data}
                    ref={inputRefs[key]}
                    inputRefs={inputRefs}
                    setSubmitConfirm={setSubmitConfirm}
                  />
                )
              ))}

              {data?.alt_unit_name !== 'Not Applicable' && data?.unit_name !== 'Not Applicable' && (
                <div className='row m-0 p-0' style={{ height: '3.5vh' }}>
                  <div className={`${data?.alt_unit_name === 'Not Applicable' ? 'text-muted' : ''}col-5 text-start  m-0 p-0`} style={{ fontSize: 13 }}>Where </div>
                  <div className={`${data?.alt_unit_name === 'Not Applicable' ? 'text-muted' : ''}text-start  m-0 p-0`} style={{ fontSize: 13, width: '2%' }}>: </div>
                  <div className='col-6 m-0 p-0'>
                    <div className='row align-items-center'>
                      <div className='col-3' style={{ fontSize: 13 }}>
                        {inputFields.slice(11, 12).map(({ label, name, type, isRequired, isDisabled }, index) => (
                          <Input
                            key={index}
                            lable={label}
                            type={type}
                            name={name}
                            value={data[name] || ''}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            handleFocus={handleFocus}
                            isRequired={isRequired}
                            isDisabled={isDisabled}
                            ref={inputRefs[name]}
                          />
                        ))}
                      </div>
                      <div className='col-1 p-0' style={{ fontSize: 13, textAlign: 'start' }}>
                        {data?.where_alternate_unit_name}
                      </div>
                      <div className='col-1' style={{ fontSize: 13 }}>=</div>
                      <div className='col-3' style={{ fontSize: 13 }}>
                        {inputFields.slice(12, 13).map(({ label, name, type, isRequired }, index) => (
                          <Input
                            key={index}
                            lable={label}
                            type={type}
                            name={name}
                            value={data[name] || ''}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            handleFocus={handleFocus}
                            isRequired={isRequired}
                            ref={inputRefs[name]}
                          />
                        ))}
                      </div>
                      <div className='col-1 p-0' style={{ fontSize: 13, textAlign: 'start' }}>
                        {data?.alt_unit_name !== 'Not Applicable' ? data?.where_unit_name : ''}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {inputFields.slice(13, 16).map(({ label, name, type, isRequired, isDisabled }, index) => (
                <InputField
                  key={index}
                  lable={label}
                  type={type}
                  name={name}
                  value={data[name] || ''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleFocus={handleFocus}
                  isRequired={isRequired}
                  isDisabled={isDisabled}
                  ref={inputRefs[name]}
                />
              ))}
              {data?.weight === 'Available' && data?.unit_name !== 'Not Applicable' && (
                inputFields.slice(16, 17).map(({ label, name, type, isRequired }, index) => (
                  <InputField
                    key={index}
                    lable={label}
                    type={type}
                    name={name}
                    value={data[name] || ''}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleFocus={handleFocus}
                    isRequired={isRequired}
                    ref={inputRefs[name]}
                  />
                ))
              )}
              {data?.weight === 'Available' && data?.alt_unit_name !== 'Not Applicable' && (
                inputFields.slice(17, 18).map(({ label, name, type, isRequired }, index) => (
                  <InputField
                    key={index}
                    lable={label}
                    type={type}
                    name={name}
                    value={data[name] || ''}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleFocus={handleFocus}
                    isRequired={isRequired}
                    ref={inputRefs[name]}
                  />
                ))
              )}
              <p className='text-start font-weight-bold m-0 p-0 mt-1 mb-1' style={{ fontWeight: 'bold' }}><u>Additional</u></p>
              {inputFields.slice(18, 19).map(({ label, name, type, isRequired, isDrop, state, isDisabled }, index) => (
                <InputField
                  key={index}
                  lable={label}
                  type={type}
                  name={name}
                  value={data[name] || ''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleFocus={handleFocus}
                  isRequired={isRequired}
                  isDisabled={isDisabled}
                  ref={inputRefs[name]}
                  show_drop={state}
                  setData={setData}
                  data={data}
                  isDrop={isDrop}

                />
              ))}
              {inputFields.slice(19, 21).map(({ label, name, type, isRequired, isDisabled }, index) => (
                <InputField
                  key={index}
                  lable={label}
                  type={type}
                  name={name}
                  value={data[name] || ''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleFocus={handleFocus}
                  isRequired={isRequired}
                  isDisabled={isDisabled}
                  ref={inputRefs[name]}
                />
              ))}
              {inputFields.slice(21, 22).map(({ label, name, type, isRequired, isDrop, state, isDisabled }, index) => (
                <InputField
                  key={index}
                  lable={label}
                  type={type}
                  name={name}
                  value={data[name] || ''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleFocus={handleFocus}
                  isRequired={isRequired}
                  isDisabled={isDisabled}
                  ref={inputRefs[name]}
                  show_drop={state}
                  setData={setData}
                  data={data}
                  isDrop={isDrop}

                />
              ))}
            </div>
            <div className='col-6'>
              <p className='text-start font-weight-bold' style={{ fontWeight: 'bold' }}><u>Statutory Details</u></p>
            </div>
            <div className='border mt-1'></div>
            <div className='col-12'>
              <div className='row'>
                <div className='col-3 align-content-center'>
                  Opening Balance :
                </div>
                <div className='col-6'>
                  {inputFields.slice(27).map(({ label, name, type, isRequired, isDisabled }, index) => (
                    <InputField
                      key={index}
                      lable={label}
                      type={type}
                      name={name}
                      value={data[name] || ''}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      handleFocus={handleFocus}
                      isRequired={isRequired}
                      isDisabled={isDisabled}
                      ref={inputRefs[name]}
                    />
                  ))}
                </div>
              </div>

            </div>

          </div>
        </Form>}
    </>
  )
})

export default StockItem
