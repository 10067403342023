import { useState, useEffect, useCallback, useRef } from "react";
import { formatInputToCapitalization } from "../Date_Formate_Utilitiy/Index";
import { flushSync } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../redux/actions/action-creator";

export const useInputActionGroup = ({ data, setData, validations, refs, check_dropdown, inputRefs }) => {
    const [submitConfirm, setSubmitConfirm] = useState('');
    const dispatch = useDispatch();
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (e.code === "Space") {
            e.preventDefault();
            const cursorPosition = e.target.selectionStart;
            if (cursorPosition === 1) {
                setData({ ...data, [name]: "" });
                return;
            }
        }
        if (e.key === 'Enter') {
            e.preventDefault();
            let nextField = null;
            if (name === "under_id") {
                nextField = value === "Primary" ? "nature_of_group" : "group_behaves_like_sub_ledger";
            } else {
                const fields = Object.keys(inputRefs);
                const currentIndex = fields.indexOf(name);
                nextField = fields[currentIndex + 1] || null;
            }


            if (nextField && inputRefs[nextField]?.current) {
                inputRefs[nextField].current.focus();
                inputRefs[nextField].current.setSelectionRange(0, inputRefs[nextField].current.value.length);
            } else {
                setSubmitConfirm('Accept')
                inputRefs[name]?.current?.blur();
            }
        }
        // Apply field-specific validations
        const validation = validations[name];
        if (validation) {
            const isValid = validation(value);
            if (isValid || value === "") {
                const updatedValue = (name === 'company_name' || name === 'mailing_name' || name === 'address' || name === 'city' || name === 'name' || name === 'ledger_name' || name === 'alise_name' || name === 'symbol' || name === 'formal_name')
                    ? formatInputToCapitalization(value)
                    : (name === 'no_of_decimal_place' || name === 'no_of_decimal_place_for_amount')
                        ? value.slice(-1)
                        : value;
                setData((prevData) => {
                    const updatedData = { ...prevData, [name]: updatedValue };
                    return updatedData;
                })


            }
        } else {
            flushSync(setData({ ...data, [name]: value }))

        }
    };

    const handleFocus = (e) => {
        const { name } = e.target;
        const focusAction = refs[name]?.onFocus;
        if (focusAction) focusAction();
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        const blurAction = refs[name]?.onBlur;
        if (blurAction) blurAction(value, setData);
    };

    const handleKeyDown = (e) => {
        const { name, value } = e.target;
        if (e.key === "Enter") {
            e.preventDefault();
            return;
        }
        if (e.shiftKey && e.key === 'Tab') {
            e.preventDefault();
            e.stopPropagation();
            const prevInputRef = refs[name]?.prev;
            if (prevInputRef && prevInputRef.current) {
                prevInputRef.current.focus();
                prevInputRef.current.setSelectionRange(0, prevInputRef?.current?.value?.length);
            }
            return;
        }
    }



    return {
        data,
        setData,
        handleChange,
        handleFocus,
        handleBlur,
        handleKeyDown,
        submitConfirm,
        setSubmitConfirm
    };
};
