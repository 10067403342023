import { groupCreate, groupListCreate, openModal, closeModal } from '../../../../../redux/actions/action-creator';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import FormData from 'form-data';
import Form from '../../../../../components/Form/Form';
import { yes_no_array, nature_of_group_arrya, applicable_not_applicable_array } from '../../../../../components/Dummi_Data';
import { prepareFormData, useFormHandler } from '../../../../../components/Custom_Hooks/useFormHandler';
import useClickOutside from '../../../../../components/Custom_Hooks/useClickOutsSide';
import InputField from '../../../../../components/Input/InputField';
import DropdownLayout_1 from '../../../../../components/Dropdown/DropdownLayout_1';
import MasterCreationFooter from '../../../../../components/Footer/Master_Footer/MasterCreationFooter';
import { useInputActionGroup } from '../../../../../components/Custom_Hooks/useInputActionGroup';


const Group = ({ title, closeModal_sec }) => {
  const dispatch = useDispatch();

  const [nameInput, setNameInput] = useState(null)
  // get redux state -----------------------------------------------------
  const modal_state = useSelector(state => state.common.modal_state);
  const group_all_list = useSelector(state => state.common.group_all_list);
  const { userData = null } = useSelector(state => state.auth);
  const str = '\u2666';

  const initialState = {
    company_id: userData?.id,
    name: '',
    alise_name: '',
    under_id: 'Capital Account',
    under: '5',
    nature_of_group: 'Liabilities',
    group_behaves_like_sub_ledger: 'No',
    nett_debit_credit_balance_for_reporting: 'No',
    used_for_calculation: 'No',
    method_to_allocate_when_used_in_purchase_invoice: `${str} Not Applicable`,
    // status:'Yes'
  }
  const [data, setData] = useState(initialState);
  const inputRefs = {
    name: useRef(null),
    alise_name: useRef(null),
    under_id: useRef(null),
    nature_of_group: useRef(null),
    group_behaves_like_sub_ledger: useRef(null),
    nett_debit_credit_balance_for_reporting: useRef(null),
    used_for_calculation: useRef(null),
    method_to_allocate_when_used_in_purchase_invoice: useRef(null),
    // status: useRef(null),
  };

  function getCurrentInputRef(name) {
    return inputRefs[name] || null;
  }

  useEffect(() => {
    const formData = new FormData();
    formData.append('company_id', userData?.id)
    dispatch(groupListCreate(formData)).then((response) => {
      if (response.status) {
      }
    })
  }, [])


  const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  const validations = {
    name: (value) => value.trim() !== "",
    alise_name: (value) => value.trim() !== "",
    under_id: (value) => group_all_list.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    nature_of_group: (value) => nature_of_group_arrya.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    group_behaves_like_sub_ledger: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    nett_debit_credit_balance_for_reporting: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    used_for_calculation: (value) => yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
    method_to_allocate_when_used_in_purchase_invoice: (value) => applicable_not_applicable_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase())),
  };

  const refs = Object.keys(inputRefs).reduce((acc, name) => {
    acc[name] = {
      onFocus: () => {
        const ref = getCurrentInputRef(name);
        if (ref) {
          setNameInput(ref)
          ref?.current?.setSelectionRange(0, ref?.current?.value?.length)
        };
        if (['under_id', 'nature_of_group', 'group_behaves_like_sub_ledger', 'nett_debit_credit_balance_for_reporting', 'used_for_calculation', 'method_to_allocate_when_used_in_purchase_invoice'].includes(name)) {
          if (name) {
            dispatch(openModal(name));
          }
        }
      },
      onBlur: (value) => {
        if (name === 'name') {
          if (!value.trim()) {
            getCurrentInputRef(name)?.current?.focus();
          }
        }
        if (['under_id', 'nature_of_group', 'group_behaves_like_sub_ledger', 'nett_debit_credit_balance_for_reporting', 'used_for_calculation', 'method_to_allocate_when_used_in_purchase_invoice'].includes(name)) {
          if (name) {
            dispatch(closeModal(name));
          }
        }
      },
    };
    return acc;
  }, {});

  const normalizedValue = (name, value) => {
    const normalizedValue = value.toLowerCase().trim();
    const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
    const validPatternNo = /^(n|o|no)?$/;
    if (normalizedValue === '') {
      setData({ ...data, [name]: 'No' })
    } else if (validPatternNo.test(normalizedValue)) {
      setData({ ...data, [name]: 'No' })
    } else if (validPatternYes.test(normalizedValue)) {
      setData({ ...data, [name]: 'Yes' })
    }
  }
  const validateForm = () => {
    const requiredFields = [
      { field: 'name', message: 'Required Name Field' },
      { field: 'under_id', message: 'Required Under Field' },
      ...(data?.under_id === 'Primary' ? [{ field: 'nature_of_group', message: 'Required Nature of Group' }] : []),
      { field: 'group_behaves_like_sub_ledger', message: 'Required Group Behave Like Sub Group' },
      { field: 'nett_debit_credit_balance_for_reporting', message: 'Required Nett Debit Field' },
      { field: 'used_for_calculation', message: 'Required Used For Calculation' },
      { field: 'method_to_allocate_when_used_in_purchase_invoice', message: 'Required Metod to Allocate' },
      // { field: 'status', message: 'Required Metod to Allocate' },
    ]

    for (let { field, message } of requiredFields) {
      if (!data?.[field]) {
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setSubmitConfirm('');
        getCurrentInputRef(field)?.current?.focus();
        return false;
      }
    }
    return true;
  };

  // Function to reset form fields-----------------------------------------
  const resetForm = () => {
    setData(initialState)
  };




  const { handleChange, handleFocus, handleBlur, handleKeyDown, setSubmitConfirm, submitConfirm, } = useInputActionGroup({
    data,
    setData,
    validations,
    refs,
    check_dropdown: modal_state?.under_id || modal_state?.nature_of_group || modal_state?.group_behaves_like_sub_ledger || modal_state?.nett_debit_credit_balance_for_reporting || modal_state?.used_for_calculation || modal_state?.method_to_allocate_when_used_in_purchase_invoice,
    inputRefs
  });

  const { handleSubmit, loading, handleFocusReset } = useFormHandler({
    validateForm,
    prepareFormData: () => prepareFormData(data),
    submitForm: (data) => dispatch(groupCreate(data)),
    shortcuts: [
      {
        key: 'a',
        ctrlKey: true,
        action: () => handleSubmit(new Event('submit')),
      },
    ],
    resetForm,
    first_ref: inputRefs?.name,
    closeModal:closeModal_sec,
    setSubmitConfirm,
    submitConfirm
  });


  const handleClickOutside = (event) => {
    if (nameInput.current) {
      event.preventDefault();
      nameInput.current.focus();
    }
  };

  const handleClickInside = (event) => {
    let clickedElement = event.target;
    if (clickedElement?.tagName &&
      (clickedElement?.tagName.toLowerCase() === 'input' ||
        clickedElement?.tagName.toLowerCase() === 'textarea')) {
      clickedElement.focus();
    }
  };

  useClickOutside(nameInput, handleClickOutside, handleClickInside);
  const inputFields = [
    { label: 'Name', name: 'name', type: 'text', isRequired: true, },
    { label: '(alise)', name: 'alise_name', type: 'text', isRequired: false, },
    { label: 'Under', name: 'under_id', type: 'text', isRequired: true, },
    { label: 'Nature Of Group', name: 'nature_of_group', type: 'text', isRequired: true, state: modal_state?.nature_of_group },
    { label: 'Group behaves like a sub-ledger', name: 'group_behaves_like_sub_ledger', type: 'text', isRequired: false, isDrop: true, state: modal_state?.group_behaves_like_sub_ledger, },
    { label: 'Nett Debit/Credit Balance for Reporting', name: 'nett_debit_credit_balance_for_reporting', type: 'text', isRequired: false, isDrop: true, state: modal_state?.nett_debit_credit_balance_for_reporting, },
    { label: 'Used for calculation (for example: taxes, discounts)', name: 'used_for_calculation', type: 'text', isRequired: false, isDrop: true, state: modal_state?.used_for_calculation, },
    { label: 'Method to allocate when used in purchase Invoice', name: 'method_to_allocate_when_used_in_purchase_invoice', type: 'text', isRequired: false, isDrop: true, state: modal_state?.method_to_allocate_when_used_in_purchase_invoice, },
  ];

  const dropdownConfig = {
    under_id: { title: 'List of Group', input_id: 'under', data: group_all_list },
    nature_of_group: { title: 'List of Group Nature', data: nature_of_group_arrya },
  };
  return (
    <Form
      handleSubmit={handleSubmit}
      handleKeyDown={handleKeyDown}
      submitConfirm={submitConfirm}
      handleFocusReset={handleFocusReset}
      title={title || 'Group Creation'}
      loading={loading}
      col='col-7'
      Footer={MasterCreationFooter}
    >
      <div className='row p-0 ps-2 m-0 mb-1'>
        {inputFields.slice(0, 3).map(({ label, name, type, value, ref, isRequired }, index) => (
          <InputField
            key={index}
            lable={label}
            type={type}
            name={name}
            value={data[name] || ''}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleFocus={handleFocus}
            isRequired={isRequired}
            ref={inputRefs[name]}
          />
        ))}
        {Object.entries(dropdownConfig).map(([key, config]) => (
          modal_state?.[key] && (
            <DropdownLayout_1
              key={key}
              title={config.title}
              state={modal_state[key]}
              data={data}
              setData={setData}
              input_value={data[key]}
              input_key={key}
              input_id={config?.input_id}
              rawData={config.data}
              ref={inputRefs[key]}
              inputRefs={inputRefs}
              setSubmitConfirm={setSubmitConfirm}
            />
          )
        ))}
      </div>
      <div className='border'></div>
      <div className='row m-0 p-0 ps-2 mt-1'>
        {
          data?.under_id === 'Primary' && (
            inputFields.slice(3, 4).map(({ label, name, type, isRequired }, index) => (
              <InputField
                key={index}
                lable={label}
                type={type}
                name={name}
                value={data[name] || ''}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleFocus={handleFocus}
                isRequired={isRequired}
                ref={inputRefs[name]}
              />
            ))
          )
        }
        {inputFields.slice(4).map(({ label, name, type, value, ref, isRequired, state, setState, isDrop }, index) => (
          <InputField
            key={index}
            lable={label}
            type={type}
            name={name}
            value={data[name] || ''}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleFocus={handleFocus}
            isRequired={isRequired}
            ref={inputRefs[name]}
            refs={inputRefs}
            show_drop={state}
            setData={setData}
            data={data}
            isDrop={isDrop}
          />
        ))}
      </div>
    </Form>
  )
}
export default React.memo(Group);























